import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDropDown from "../../../../Components/CustomDropDown/CustomDropDown";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NewDepositModel from "./NewDepositModel";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
const FundsDeposit = () => {
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [fundsDepositStatus, setFundsDepositStatus] = useState(null);
  const [dipositeList, setDipositeList] = useState([]);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [searchDeposit, setSearchDeposit] = useState("");
  const debouncedSearch = useDebounce(searchDeposit, 500);
  const fundsDepositStatusList = [
    {
      id: null,
      name: "All",
    },
    {
      id: 0,
      name: "Pending",
    },
    {
      id: 1,
      name: "Waiting",
    },
    {
      id: 2,
      name: "Approved",
    },
    {
      id: 3,
      name: "Cancelled - By User",
    },
    {
      id: 4,
      name: "Rejected",
    },
  ];
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No.",
      minWidth: 100,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      hideOnMobile: true,
    },
    {
      field: "user_name",
      headerName: "Name",
      minWidth: 200,
      filterable: true,
      sortable: true,

      renderCell: (params) => <span>{params?.row?.user_name || "--"}</span>,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      filterable: true,
      sortable: true,
      hideOnMobile: true,
      renderCell: (params) => <span>{params?.row?.email || "--"}</span>,
    },
    {
      field: "total_amount",
      headerName: "Amount",
      minWidth: 200,
      renderCell: (params) => {
        const balance = params?.row?.total_amount || 0;
        const formattedBalance = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(balance);

        return (
          <Tooltip title={formattedBalance}>
            <span>{`$${formattedBalance}`}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "payment_receipt",
      headerName: "Deposite Proof",
      minWidth: 200,
      filterable: false,
      editable: false,
      sortable: false,
      hideOnMobile: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={"Download Receipt"}>
          <IconButton
            onClick={() => window.open(params?.row?.payment_receipt, "_blank")}
          >
            <ReceiptIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "date",
      headerName: "Registered Date",
      minWidth: 250,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { date } = params?.row;
        const seconds = date?.seconds;
        const nanoseconds = date?.nanoseconds;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        const dateP = moment(milliseconds);
        return <div>{dateP.format("MMMM Do YYYY, h:mm:ss A")}</div>;
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      minWidth: 200,
      filterable: false,
      editable: false,
      sortable: false,
      hideOnMobile: true,
      disableColumnMenu: true,
      renderCell: (params) => <span>{`${params?.row?.notes}` || "--"}</span>,
    },
    {
      field: "txn_id",
      headerName: "TXN",
      minWidth: 200,
      filterable: false,
      editable: false,
      sortable: false,
      hideOnMobile: true,
      disableColumnMenu: true,
      renderCell: (params) => <span>{`${params?.row?.txn_id}` || "--"}</span>,
    },
    {
      field: "request_status",
      headerName: "Status",
      minWidth: 160,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { request_status } = params?.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "10px",
              position: "relative",
            }}
          >
            {request_status === 0 && (
              <IconButton
                sx={{
                  background: "#ff9800",
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "#f57c00",
                  },
                }}
                onClick={() => handleAcceptRejectOnChange(params?.row, 1)}
              >
                <HourglassEmptyIcon sx={{ color: "#fff" }} />
              </IconButton>
            )}
            {request_status === 1 && (
              <>
                <IconButton
                  sx={{
                    background: "#4caf50",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "#388e3c",
                    },
                  }}
                  onClick={() => handleAcceptRejectOnChange(params?.row, 2)}
                >
                  <CheckIcon sx={{ color: "#fff" }} />
                </IconButton>
                <IconButton
                  sx={{
                    background: "red",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "red",
                    },
                  }}
                  onClick={() => handleAcceptRejectOnChange(params?.row, 4)}
                >
                  <CloseIcon sx={{ color: "#fff" }} />
                </IconButton>
              </>
            )}
            {request_status === 2 && (
              <Chip
                label="Approved"
                sx={{
                  color: "#fff",
                  backgroundColor: "#4CAF50",
                  cursor: "pointer",
                }}
              />
            )}
            {request_status === 3 && (
              <Chip
                label="Cancelled By User"
                sx={{
                  color: "#fff",
                  backgroundColor: "#F44336",
                  cursor: "pointer",
                }}
              />
            )}
            {request_status === 4 && (
              <Chip
                label="Rejected"
                sx={{
                  color: "#fff",
                  backgroundColor: "#D32F2F",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    const unsubscribe = getFundsDeposit(
      page,
      pageSize,
      fundsDepositStatus,
      debouncedSearch
    );
    return () => {
      unsubscribe();
    };
  }, [page, pageSize, fundsDepositStatus, debouncedSearch]);

  const getFundsDeposit = (
    currentPage = 0,
    pageSize = 50,
    fundsDepositStatus = null,
    debouncedSearch = ""
  ) => {
    setIsLoading(true);
    let q = query(
      collection(DATABASE, "transection_requests"),
      where("request_type", "==", 0),
      orderBy("date", "desc")
    );
    if (fundsDepositStatus !== null) {
      q = query(q, where("request_status", "==", fundsDepositStatus));
    }
    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const transactions = querySnapshot?.docs?.map((doc, index) => ({
          rowid: index + 1,
          id: doc.id,
          ...doc.data(),
        }));
        const usersSnapshot = await getDocs(collection(DATABASE, "users"));
        const usersList = usersSnapshot?.docs?.map((userDoc) => ({
          doc_id: userDoc?.id,
          email: userDoc?.data()?.email,
          name: userDoc?.data()?.name || userDoc?.data()?.full_name,
          ...userDoc?.data(),
        }));
        const transactionsWithUserNames = transactions?.map((transaction) => {
          const matchedUser = usersList?.find(
            (user) => user?.doc_id === transaction?.user_id
          );
          return {
            ...transaction,
            user_name: matchedUser?.name || "Unknown",
            email: matchedUser?.email || "--",
          };
        });
        const filteredTransactions = debouncedSearch
          ? transactionsWithUserNames?.filter(
              (transaction) =>
                transaction?.email
                  .toLowerCase()
                  .includes(debouncedSearch.toLowerCase()) ||
                transaction?.user_name
                  .toLowerCase()
                  .includes(debouncedSearch.toLowerCase())
            )
          : transactionsWithUserNames;
        const paginatedTransactions = filteredTransactions?.slice(
          currentPage * pageSize,
          (currentPage + 1) * pageSize
        );
        setDipositeList(paginatedTransactions);
        setTotalCount(filteredTransactions?.length);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching deposits:", error);
        setIsLoading(false);
      }
    );
    return unsubscribe;
  };
  const getTimestamp = () => {
    const currentDate = moment.utc();
    const hour = currentDate.hour();
  
    if (hour >= 21) {
      currentDate.add(1, "day");
    }
  
    return currentDate.toDate();
  };
  
  

  const handleAcceptRejectOnChange = async (Data, Status) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You want to ${
        Status === 2 ? "approve" : Status === 1 ? "process" : "reject"
      } this deposit`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        if (!Data?.user_id) {
          throw new Error("User ID is missing");
        }
        if (Status === 2) {
          const userDoc = await getDoc(doc(DATABASE, "users", Data.user_id));
          if (!userDoc.exists()) {
            throw new Error("User does not exist");
          }
          const currentBalance = userDoc?.data()?.net_balance || 0;
          const newBalance = currentBalance + parseFloat(Data?.total_amount);
          const ledgerEntry = {
            new_txn_amount: parseFloat(Data?.total_amount),
            user_id: Data?.user_id,
            date: getTimestamp(),
            previous_balance: currentBalance,
            current_balance: newBalance,
            transection_type: 0,
            profit_loss_percent: 0,
            profit_loss_balance: 0,
            doc_id: Data?.doc_id,
          };
          let res = await setDoc(
            doc(DATABASE, "ledgers", Date.now().toString()),
            ledgerEntry
          );
          await updateDoc(doc(DATABASE, "users", Data?.user_id), {
            net_balance: newBalance,
          });
        }
        await updateDoc(doc(DATABASE, "transection_requests", Data?.doc_id), {
          request_status: Status,
          request_approval_time: Timestamp.now(),
        });
        toast.success("Deposit status updated successfully!", {
          autoClose: 3000,
        });
        setPage(0);
        getFundsDeposit(page, pageSize, fundsDepositStatus);
      } catch (error) {
        console.error("Error updating deposit status:", error);
        toast.error(
          error.message || "Something went wrong. Try again!",
          "error"
        );
      }
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    getFundsDeposit(0, newPageSize, fundsDepositStatus);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    getFundsDeposit(newPage, pageSize, fundsDepositStatus);
  };
  const handleFundDepositeStatus = (id) => {
    setFundsDepositStatus(id === null ? null : id);
    setPage(0);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          paddingTop:{xs:'60px', sm:"0px"}
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Funds Deposit
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <CustomSearch
              placeholder="Search (Email, Full Name)"
              value={searchDeposit}
              onChange={(event) => setSearchDeposit(event.target.value)}
              onClear={() => setSearchDeposit("")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomDropDown
                    sx={{ width: "250px", height: "46px" }}
                    placeholder={"Select Deposit"}
                    menuList={fundsDepositStatusList}
                    value={fundsDepositStatus}
                    onChange={(e) => handleFundDepositeStatus(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            paddingTop: "24px",
          }}
        >
          <TableContainer
            rows={dipositeList}
            columns={columns}
            loading={isLoading}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            paginationShow={true}
          />
        </Box>
      </Box>
      <NewDepositModel
        open={isDepositModalOpen}
        onClose={() => setIsDepositModalOpen(false)}
      />
    </>
  );
};

export default FundsDeposit;