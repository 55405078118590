import React from "react";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
const TopHeader = (props) => {
  const {
    type,
    variant,
    fullWidth,
    onSubmit,
    btnTitle,
    children,
    disabled,
    loading,
    showButton
  } = props;
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        width: "100%",
        padding: "0px 15px",
        zIndex: "10",
        boxShadow:"0px 0px 10px rgba(0,0,0,0.5)",
      }}
    >
      <Box
        sx={{
          width: "25%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          onClick={props.onClick}
          sx={{
            width: "35px",
            height: "35px",
            borderRadius: "4px",
            background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
            alignItems: "center",
            justifyContent: "center",
            outline: "none",
            border: "none",
            cursor: "pointer",
            ":hover": {
              background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
            },
          }}
        >
          <KeyboardArrowLeftIcon
            sx={{
              fontSize: "32px",
              color: "#fff",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#000",
            fontSize: {xs:'18px',md:"22px"},
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "25%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {showButton && 
        <Button
            type={type}
            variant={variant}
            fullWidth={fullWidth}
            disabled={disabled || loading}
            sx={{
              fontWeight: "700",
              fontSize: "18px",
              height: "48px",
              maxWidth: "150px",
              width:'100%',
              textTransform: "unset",
              background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
              color: "#ffffff !important",
              border:'1.5px solid #0C2C50',
              "&:hover": {
                border:'1.5px solid #0C2C50',
                backgroundColor: "transparent !important",
                color: "#ffffff !important",
              },
            }}
            onClick={onSubmit}
            className={"customBtn"}
          >
            {loading ? (
              <>
               <CircularProgress />
              </>
            ) : (
              btnTitle
            )}
            {children}
          </Button>
        }
      </Box>
    </Box>
  );
};

export default TopHeader;
