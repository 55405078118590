import React, { Fragment, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import DayWisePerformance from "./DayWisePerformance";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import { useLocation, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import PerformanceDailyGraph from "./PerformanceMonthlyGraph";
import CustomerSelection from "./CustomerSelection";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { DATABASE } from "../../../../Config/firebase";
import { toast } from "react-toastify";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import axios from "axios";
dayjs.extend(isSameOrBefore);
const AddMonthlyGraph = () => {
  const navigate = useNavigate();
  const [isLoadingProgress, setIsLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [total_Monthly_percantage, setTotal_monthly_percantage] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [multipleUsersEntryList, setMultipleUsersEntryList] = useState([]);
  
  const [activeStep, setActiveStep] = useState(parseInt(sessionStorage.getItem("activeStep")) || 0);
  const [ledgers, setLedgers] = useState([]);
  const [isNotify, setIsNotify] = useState([]);
  console.log('isNotify', isNotify);
  console.log('multipleUsersEntryList', multipleUsersEntryList);

  const steps = ["Days Performance", "Month Graph", "Users Selection"];
  const fetchCustomerList = async () => {
    setIsLoading(true);
    const customerQuery = query(
      collection(DATABASE, "users"),
      orderBy("registered_date", "desc"),
      where("status", "==", 1),
    );
    onSnapshot(
      customerQuery,
      (snapshot) => {
        const data = snapshot.docs
          .map((doc, index) => ({
            rowid: index + 1,
            id: doc.id,
            ...doc.data(),
          }))
          .filter((doc) => doc.role === 1);
        setCustomerList(data);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching customers:", error);
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    fetchCustomerList();
  }, []);
  const handleNext = async () => {
    setIsLoadingProgress(0);
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress += 1;
      setIsLoadingProgress(progress);
      if (progress >= 100) {
        clearInterval(progressInterval);
      }
    }, 30);
    if (activeStep === 0) {
      const step = 100 / customerList?.length;
      let multipleUsersEntry = [];
      try {
        const existingLedgers = ledgers?.filter((ledger) =>
          customerList?.some((customer) => customer?.doc_id === ledger?.user_id)
        );
        const userPromises = customerList?.map(async (userID) => {
          const userLedgers = existingLedgers?.filter(
            (e) => e?.user_id === userID?.doc_id
          );
          if (userLedgers.length === 0) return;

          const userNetBalance = await getCustomerDetails(
            userLedgers[0]?.user_id
          );
          let totalMonthlyProfitLoss = 0;
          let closingPercentageAccumulated = 0;
          let previousBalance = null;
          const graphPoints = [];

          const totalMonthlyPercentage =
            formik?.values?.dayWisePerformance?.select_days.reduce(
              (acc, dayData) => acc + (parseFloat(dayData?.percentage) || 0),
              0
            );
          const day_avg_percentage =
            totalMonthlyPercentage /
            formik?.values?.dayWisePerformance?.select_days.length;

          formik?.values?.dayWisePerformance?.select_days.forEach(
            (dayData, index) => {
              const day = index + 1;
              const currentDate = dayjs(
                formik?.values?.dayWisePerformance?.select_Month
              ).date(day);

              const currentPercentage = parseFloat(dayData?.percentage) || 0;
              const close = parseFloat(dayData?.closing) || 0;
              const open = parseFloat(dayData?.opening) || 0;
              const highest = parseFloat(dayData?.highest) || 0;
              const lowest = parseFloat(dayData?.lowest) || 0;
              console.log(userLedgers, "uselesdgers");
              const ledgerEntriesBeforeDate = userLedgers
                .filter((entry) => {
                  const entryDate =
                    entry?.date instanceof Timestamp
                      ? entry?.date.toDate()
                      : new Date(entry?.date);

                  return dayjs(entryDate).isSameOrBefore(currentDate);
                })
                .sort((a, b) => {
                  const dateA =
                    a?.date instanceof Timestamp
                      ? a?.date.toDate()
                      : new Date(a?.date);
                  const dateB =
                    b?.date instanceof Timestamp
                      ? b?.date.toDate()
                      : new Date(b?.date);

                  return dayjs(dateB).diff(dayjs(dateA));
                });

              previousBalance =
                ledgerEntriesBeforeDate.length > 0
                  ? ledgerEntriesBeforeDate[0]?.current_balance ||
                  ledgerEntriesBeforeDate[0]?.balance
                  : previousBalance || 0;

              const dailyProfitLoss =
                (previousBalance * day_avg_percentage) / 100;
              const closingBalance = previousBalance + dailyProfitLoss;
              totalMonthlyProfitLoss += dailyProfitLoss;

              graphPoints.push({
                closing_balance: closingBalance,
                opening_balance: previousBalance,
                amount: currentPercentage,
                opening_percentage:
                  index === 0 ? 0 : closingPercentageAccumulated,
                closing_percentage: currentPercentage,
                date: Timestamp.fromDate(currentDate.toDate()),
                open,
                close,
                high: highest,
                low: lowest,
                volume: 0,
              });

              previousBalance = closingBalance;
              closingPercentageAccumulated = currentPercentage;
            }
          );

          const profitToUser =
            (parseFloat(totalMonthlyProfitLoss) * (100 - 30)) / 100;
          const userDoc = doc(DATABASE, "users", userID?.doc_id);
          const userData = await getDoc(userDoc);
          const currentNetBalance = userData.exists()
            ? userData.data()?.net_balance || 0
            : 0;
          const isFullWithdrawal = userLedgers.some(
            (entry) => entry.type === 2 && entry.amount === currentNetBalance
          );
          // const grossPerformancePercentage =
          //   userNetBalance !== 0
          //     ? (parseFloat(totalMonthlyProfitLoss) * 100) / userNetBalance
          //     : 0;
          const grossPerformancePercentage =
            currentNetBalance !== 0
              ? (parseFloat(totalMonthlyProfitLoss) * 100) / currentNetBalance
              : 0;
          const percentageAfterCommission =
          currentNetBalance !== 0
              ? (parseFloat(totalMonthlyProfitLoss) * (100 - 30)) / currentNetBalance
              : 0;

          multipleUsersEntry.push({
            doc_id: `${userID?.doc_id}_${dayjs().format("YYYYMMDDHHmmss")}`,
            start_date: Timestamp.fromDate(
              formik?.values?.dayWisePerformance?.select_Month
                .startOf("month")
                .toDate()
            ),
            end_date: Timestamp.fromDate(
              formik?.values?.dayWisePerformance?.select_Month
                .endOf("month")
                .toDate()
            ),
            current_month_date: Timestamp.fromDate(
              formik?.values?.dayWisePerformance?.select_Month
                .add(1, "month")
                .startOf("month")
                .toDate()
            ),
            graph_month: formik?.values?.dayWisePerformance?.select_Month.month(),
            chart_month:
              formik?.values?.dayWisePerformance?.select_Month.month() + 1,
            graph_month_date: Timestamp.fromDate(
              formik?.values?.dayWisePerformance?.select_Month
                .startOf("month")
                .toDate()
            ),
            graph_year: formik?.values?.dayWisePerformance?.select_Month.year(),
            graph_points: graphPoints,
            total_Monthly_Profit_Loss: parseFloat(
              totalMonthlyProfitLoss?.toFixed(2)
            ),
            user_id: userID,
            total_Monthly_percantage: totalMonthlyPercentage,
            day_avg_percentage,
            amount_before_performance: isFullWithdrawal ? 0 : userNetBalance,
            gross_performance_percentage: grossPerformancePercentage,
            gross_performance_balance:
              parseFloat(totalMonthlyProfitLoss) + userNetBalance,
            fiddux_commission_percentage: 30,
            fiddux_commission: parseFloat(totalMonthlyProfitLoss * 30) / 100,
            percentage_after_commission: percentageAfterCommission,
            balance_after_commission: profitToUser + userNetBalance,
            profit_to_user: profitToUser,
          });
          setIsLoadingProgress((prev) => Math.min(prev + step, 100));
        });
        await Promise.all(userPromises);
        await new Promise((resolve) => setTimeout(resolve, 100));
        setMultipleUsersEntryList(multipleUsersEntry);
      } catch (error) {
        console.error("Error processing users:", error);
      } finally {
        clearInterval(progressInterval);
        setTimeout(() => setIsLoadingProgress(100), 500);
      }
    }
    const nextStep = Math.min(activeStep + 1, steps.length - 1);
    setActiveStep(nextStep);
    sessionStorage.setItem("activeStep", nextStep);
  };
  const handleBack = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    setActiveStep(prevStep);
    sessionStorage.setItem("activeStep", prevStep);
  };
  const isFormValid = () => {
    const hasUsers = formik.values.select_user.length > 0;
    return hasUsers;
  };
  const isStepValid = (step) => {
    switch (step) {
      case 0:
        return formik.values.dayWisePerformance.select_days.some(
          (day) => day.percentage > 0
        );
      case 1:
        return true;
      case 2:
        return formik.values.select_user.length > 0;
      default:
        return false;
    }
  };
  const getLedgersList = () => {
    setIsLoading(true);
    const sDate = new Date(formik?.values?.dayWisePerformance?.select_Month);
    const q = query(
      collection(DATABASE, "ledgers")
    );
    return onSnapshot(
      q,
      (querySnapshot) => {
        const allData = querySnapshot.docs
          .filter((doc) => doc.data())
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setLedgers(allData);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching ledgers:", error);
        setIsLoading(false);
      }
    );
  };
  const getCustomerDetails = async (ID) => {
    setIsLoading(true);
    try {
      const transactionRef = collection(DATABASE, "users");
      const q = query(
        transactionRef,
        where("doc_id", "==", ID),
        where("status", "==", 1)
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot?.docs.map((doc) => doc?.data());
      return data[0]?.net_balance;
    } catch (error) {
      console.error("Error fetching customer details:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      select_user: [],
      dayWisePerformance: {
        select_Month: dayjs().startOf("month"),
        select_days: Array.from({ length: dayjs().daysInMonth() }, (_, i) => ({
          day: i + 1,
          percentage: 0,
          opening: 0,
          closing: 0,
          highest: 0,
          lowest: 0,
        })),
      },
    },
    validationSchema: Yup.object({
      select_user: Yup.array().min(1, "At least one user is required."),
      dayWisePerformance: Yup.object({
        select_days: Yup.array().of(
          Yup.object({
            percentage: Yup.number()
              .required("Percentage is required.")
              .typeError("Must be a number")
              .min(-100, "Percentage cannot be less than -100.")
              .max(100, "Percentage cannot be more than 100."),
          })
        ),
      }),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setIsLoadingProgress(10);
      try {
        let multipleUsersLedgers = [];
        let transectionUsers = [];
        let monthly_closing_user = [];
        const yearlyPercentagesMap = {};
        let matchingEntries = [];
        const setDocPromises = values?.select_user?.map(
          async (userId, index) => {
            const userDocId = userId?.doc_id || userId;
            const matchingEntry = multipleUsersEntryList?.find((entry) => {
              return entry.user_id.doc_id === userDocId;
            });

            let modifiedentry = {
              ...matchingEntry,
              user_id: matchingEntry?.user_id?.doc_id,
              userIDCommoent: "1234567898123",
              isNotify: isNotify.includes(userDocId),
            };
            if (modifiedentry) {
              matchingEntries.push(modifiedentry);
              const monthYearDocId = `${userDocId}_${dayjs().format("YYYYMMDDHHmmss")}`;
              const docRef = doc(DATABASE, "monthly_grpahs", monthYearDocId);
              await setDoc(docRef, modifiedentry);
              setIsLoadingProgress((prev) => Math.min(prev + 10, 50));
              if (index === 0) {
                const selectedMonthName =
                  values?.dayWisePerformance?.select_Month?.format("MMYY");
                const monthly_graph = "monthly_graphs";
                const globalGraphsMonthlyDocRef = doc(
                  DATABASE,
                  "global_data",
                  monthly_graph,
                  "graphs",
                  `${selectedMonthName}`
                );
                await setDoc(globalGraphsMonthlyDocRef, modifiedentry);
              }
              const {
                user_id,
                fiddux_commission,
                profit_to_user,
                gross_performance_percentage,
                balance_after_commission,
                total_Monthly_percantage,
                userIDCommoent,
                percentage_after_commission,
                graph_month,
                graph_year,
                current_month_date,
              } = modifiedentry;
              const userDoc = doc(DATABASE, "users", user_id);
              const userData = await getDoc(userDoc);
              const currentNetBalance = userData.exists()
                ? userData.data()?.net_balance || 0
                : 0;
              const updatedNetBalance = balance_after_commission;
              multipleUsersLedgers.push({
                profit_loss_balance: parseFloat(profit_to_user),
                user_id,
                profit_loss_percent: gross_performance_percentage,
                date: current_month_date,
                created_at: Timestamp.now(),
                doc_id: `${user_id}_${dayjs().format("YYYYMMDDHHmmss")}`,
                new_txn_amount: parseFloat(profit_to_user),
                previous_balance: currentNetBalance,
                current_balance: updatedNetBalance,
                transection_type: 3,
              });
              monthly_closing_user?.push({
                admin_comission: fiddux_commission,
                closing_balance: balance_after_commission,
                closing_month: graph_month,
                closing_year: graph_year,
                date: Timestamp.now(),
                doc_id: `${user_id}_${dayjs().format("YYYYMMDDHHmmss")}`,
                is_email_notified: true,
                percent: percentage_after_commission,
                profit_loss: parseFloat(profit_to_user),
                profit_loss_bool: true,
                user_id,
                user_performance: gross_performance_percentage,
              });
              transectionUsers.push({
                admin_commission: fiddux_commission,
                date: Timestamp.now(),
                request_status: 5,
                request_type: 2,
                notes: "",
                payment_receipt: "",
                user_id,
                remaining_balance: 0,
                profit_loss: parseFloat(profit_to_user),
                total_amount: 0,
                profit_loss_percent: gross_performance_percentage,
                request_approval_time: Timestamp.now(),
                transection_fee: 0,
                txn_id: Number(dayjs()?.format("YYYYMMDDHHmmss")),
                withdraw_amount: 0,
                otp: "0",
                otp_verify: true,
                doc_id: `${user_id}_${dayjs().format("YYYYMMDDHHmmss")}`,
              });
              if (!yearlyPercentagesMap[userIDCommoent]) {
                yearlyPercentagesMap[userIDCommoent] = Array(12).fill(0);
              }
              const currentMonth =
                values.dayWisePerformance.select_Month.month();
              yearlyPercentagesMap[userIDCommoent][currentMonth] +=
                total_Monthly_percantage;
            }
            return Promise.resolve();
          }
        );
        await Promise.all(setDocPromises);
        setIsLoadingProgress(60);
        const netBalanceUpdatePromises = multipleUsersLedgers?.map(
          async ({ user_id, current_balance }) => {
            if (current_balance !== undefined) {
              const updateNetBalance = { net_balance: current_balance };
              await updateDoc(
                doc(DATABASE, "users", user_id),
                updateNetBalance
              );
            }
          }
        );
        const yearlyPromises = Object.keys(yearlyPercentagesMap).map(
          async (userID) => {
            const currentMonth =
              values.dayWisePerformance.select_Month.month() + 1;
            const currentYear = values.dayWisePerformance.select_Month.year();
            const totalMonthlyPercentage =
              yearlyPercentagesMap[userID][currentMonth - 1];
            let runningTotal = 0;
            const yearlyGraphCollection = collection(DATABASE, "yearly_graph");
            const querySnapshot = await getDocs(
              query(
                yearlyGraphCollection,
                where("month", "==", currentMonth),
                where("year", "==", currentYear)
              )
            );

            if (!querySnapshot.empty) {
              console.log(
                "Entry for this month and year already exists. Skipping..."
              );
              return;
            }
            const previousPointsSnapshot = await getDocs(
              query(yearlyGraphCollection, where("userID", "==", userID))
            );

            previousPointsSnapshot.forEach((doc) => {
              runningTotal += doc.data().amount;
            });
            runningTotal += totalMonthlyPercentage;
            const yearlyData = {
              month: currentMonth,
              year: currentYear,
              amount: totalMonthlyPercentage,
              point: runningTotal,
              created_at: Timestamp.now(),
            };
            const autoIDRef = await addDoc(yearlyGraphCollection, yearlyData);
            await setDoc(autoIDRef, { ...yearlyData, doc_id: autoIDRef.id });
          }
        );
        await Promise.all(yearlyPromises);
        await Promise.all(netBalanceUpdatePromises);
        setIsLoadingProgress(80);
        if (multipleUsersEntryList?.length > 0) {
          const saveLedgerPromises = multipleUsersLedgers?.map(
            async (ledger) => {
              const docRef = doc(DATABASE, "ledgers", ledger?.doc_id);
              await setDoc(docRef, ledger);
            }
          );
          await Promise.all(saveLedgerPromises);
          const saveTransectionPromises = transectionUsers?.map(
            async (trans) => {
              const docRef = doc(
                DATABASE,
                "transection_requests",
                trans?.doc_id
              );
              await setDoc(docRef, trans);
            }
          );
          await Promise.all(saveTransectionPromises);
          const saveMonthlyClosingPromises = monthly_closing_user?.map(
            async (m) => {
              const docRef = doc(DATABASE, "monthly_closings", m?.doc_id);
              await setDoc(docRef, m);
            }
          );
          await Promise.all(saveMonthlyClosingPromises);
          setIsLoadingProgress(90);
        }
        try {
          const selectedMonth = values?.dayWisePerformance?.select_Month
          axios.post(
            'https://generateandsendpdf-lq7gauxrna-uc.a.run.app',
            // "https://generateandsendpdf-f2yyu737lq-uc.a.run.app",
            { matchingEntries, isNotify, selectedMonth },
            { headers: { 'Content-Type': 'application/json' } }
          );
        } catch (error) {
          console.log('error', error);
        }
        setIsLoadingProgress(100);
        toast.success("Monthly Graph Added Successfully!");

      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Failed to add Monthly Graph.");
      } finally {
        setIsLoading(false);
        setTimeout(() => setIsLoadingProgress(0), 500);
        resetForm();
        setActiveStep(0);
        sessionStorage.removeItem("activeStep");
        navigate(-1);
      }
    },
  });
  console.log('formik', formik?.values?.select_user);

  useEffect(() => {
    if (activeStep == 2 || activeStep == 0) {
      getLedgersList();
    }
  }, [activeStep, formik.values.dayWisePerformance.select_Month]);
  useEffect(() => {
    if (activeStep === 0) {
      sessionStorage.setItem(
        "select_Month",
        formik.values.dayWisePerformance.select_Month.toISOString()
      );
      sessionStorage.setItem(
        "select_days",
        JSON.stringify(formik.values.dayWisePerformance.select_days)
      );
    } else if (activeStep === 2) {
      sessionStorage.setItem(
        "select_user",
        JSON.stringify(formik.values.select_user)
      );
    }
  }, [formik.values, activeStep]);
  return (
    <Fragment>
      <TopHeader
        title={"Add Monthly"}
        onClick={() => {
          navigate(-1);
          sessionStorage.clear();
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {isLoadingProgress > 0 && isLoadingProgress < 100 ? (
            <Box
              sx={{
                position: "fixed",
                inset: 0,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "hidden",
                height: "100vh",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={isLoadingProgress}
                sx={{ width: "80%", borderRadius: "5px", marginBottom: 2 }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "#fff",
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: 20,
                }}
              >
                Please wait while we process your request.
              </Typography>
            </Box>
          ) : null}
          <Box
            sx={{
              maxWidth: { xs: "95%", lg: "95%", xl: "95%" },
              width: "100%",
              p: 3,
              margin: "50px auto 20px auto",
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ padding: "12px", width: "500px", margin: "0 auto" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ paddingTop: "32px" }}>
              {activeStep === 0 && (
                <DayWisePerformance
                  dayWisePerformance={formik?.values?.dayWisePerformance}
                  setFieldValue={formik?.setFieldValue}
                  errors={formik.errors.dayWisePerformance?.select_days}
                  touched={formik.touched.dayWisePerformance?.select_days}
                  total_Monthly_percantage={total_Monthly_percantage}
                  setTotal_monthly_percantage={setTotal_monthly_percantage}
                />
              )}
              {activeStep === 1 && (
                <PerformanceDailyGraph
                  dayWisePerformance={formik?.values?.dayWisePerformance}
                  multipleUsersEntryList={multipleUsersEntryList[0]}
                />
              )}
              {activeStep === 2 && (
                <CustomerSelection
                  multipleUsersEntry={multipleUsersEntryList}
                  setMultipleUsersEntryList={setMultipleUsersEntryList}
                  select_user={formik?.values?.select_user}
                  setFieldValue={formik?.setFieldValue}
                  total_Monthly_percantage={total_Monthly_percantage}
                  setIsNotify={setIsNotify}
                  isNotify={isNotify}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gridGap: "10px",
                pt: 1,
              }}
            >
              <IconButton
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  color: "#FFFFFF",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#FFFFFF",
                  },
                  "&.Mui-disabled": {
                    background: "#B0BEC5",
                    color: "#FFFFFF",
                  },
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              {activeStep !== 2 && (
                <IconButton
                  onClick={handleNext}
                  disabled={
                    !isStepValid(activeStep) || activeStep === steps.length - 1
                  }
                  sx={{
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#FFFFFF",
                    "&:hover": {
                      background:
                        "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                      color: "#FFFFFF",
                    },
                    "&.Mui-disabled": {
                      background: "#B0BEC5",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              )}
              {activeStep === 2 && (
                <Button
                  type={"Submit"}
                  disabled={!isFormValid()}
                  sx={{
                    fontWeight: "700",
                    fontSize: "18px",
                    height: "48px",
                    maxWidth: "150px",
                    width: "100%",
                    textTransform: "unset",
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#ffffff !important",
                    border: "1.5px solid #0C2C50",
                    "&:hover": {
                      border: "1.5px solid #0C2C50",
                      backgroundColor: "transparent !important",
                      color: "#ffffff !important",
                    },
                    "&.Mui-disabled": {
                      background: "#B0BEC5",
                      color: "#FFFFFF",
                      border: "1.5px solid #B0BEC5",
                    },
                  }}
                  className={"customBtn"}
                >
                  {isLoading ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};

export default AddMonthlyGraph;
