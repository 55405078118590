import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import CustomYearMonthPicker from "../../../../Components/CustomYearMonthPicker/CustomYearMonthPicker";
import CustomTextField from "../../../../Components/CustomTextField/CustomTextField";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import debounce from "lodash.debounce";
import Papa from "papaparse";
import Swal from "sweetalert2";
const DayRow = React.memo(({ i, day, updateDayData }) => {
  return (
    <TableBody
      sx={{
        border: "1px solid #0000003D",
      }}
    >
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          <Typography
            sx={{
              textAlign: "center",
            }}
          >
            {i + 1}
          </Typography>
        </TableCell>
        {["opening", "closing", "highest", "lowest", "percentage"].map(
          (field) => (
            <TableCell
              key={field}
              sx={{
                textAlign: "center",
              }}
            >
              <CustomTextField
                type="number"
                value={day[field] || ""}
                onChange={(e) => updateDayData(i, field, e.target.value)}
                InputProps={{ inputProps: { min: 0 } }}
                placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                sx={{
                  mt: 0,
                  width: "150px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "1px solid #000",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "12px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "#000", fontSize: "16px" }}
              />
            </TableCell>
          )
        )}
      </TableRow>
    </TableBody>
  );
});

const DayWisePerformance = ({
  dayWisePerformance,
  setFieldValue,
  total_Monthly_percantage,
  setTotal_monthly_percantage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [netBalance, setNetBalance] = useState(0);
  const fileInputRef = useRef();
  useEffect(() => {
    const unsubscribe = getCustomerList();
    return () => unsubscribe();
  }, []);

  const getCustomerList = () => {
    setIsLoading(true);
    const q = query(
      collection(DATABASE, "users"),
      orderBy("registered_date", "desc")
    );
    return onSnapshot(q,(querySnapshot) => {
        const allData = querySnapshot?.docs
          .filter((doc) => doc?.data()?.role === 1)
          .map((doc, index) => ({
            rowid: index + 1,
            id: doc?.id,
            ...doc?.data(),
          }));
        const totalBalance = allData.reduce(
          (acc, data) => acc + (data?.net_balance || 0),
          0
        );
        setNetBalance(totalBalance);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching customers:", error);
        setIsLoading(false);
      }
    );
  };

  const getDaysInMonth = (month) => dayjs(month).daysInMonth();

  useEffect(() => {
    const lastMonth = dayjs().subtract(1, "month");
    const daysInLastMonth = getDaysInMonth(lastMonth);
    setFieldValue("dayWisePerformance.select_Month", lastMonth);
    setFieldValue(
      "dayWisePerformance.select_days",
      Array.from({ length: daysInLastMonth }, (_, i) => ({
        day: i + 1,
        percentage: 0,
      }))
    );
  }, [setFieldValue]);

  useEffect(() => {
    if (dayWisePerformance?.select_days) {
      const total = dayWisePerformance?.select_days?.reduce((acc, day) => {
        const percentage = Number(day?.percentage);
        return acc + (isNaN(percentage) ? 0 : percentage);
      }, 0);
      setTotal_monthly_percantage(total);
    }
  }, [dayWisePerformance?.select_days, setTotal_monthly_percantage]);

  const memoizedProfitLoss = useMemo(
    () => (netBalance * total_Monthly_percantage) / 100,
    [netBalance, total_Monthly_percantage]
  );

  const memoizedTotalMonthlyPercentage = useMemo(() => {
    return dayWisePerformance?.select_days?.reduce((acc, day) => {
      const percentage = Number(day?.percentage);
      return acc + (isNaN(percentage) ? 0 : percentage);
    }, 0);
  }, [dayWisePerformance?.select_days]);

  const updateDayData = useCallback(debounce((i, field, value) => {
    const updatedValue = value === "" ? 0 : parseFloat(value);
    const updatedDays = [...dayWisePerformance?.select_days];
    updatedDays[i] = { ...updatedDays[i], [field]: updatedValue };
    setFieldValue("dayWisePerformance.select_days", updatedDays);
  }, 300), [dayWisePerformance, setFieldValue]);

  const handleCSVUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const parsedData = result.data;
          const csvMonth = dayjs(parsedData[0]['Date'], 'DD-MM-YYYY').format('MMMM YYYY');
          const selectedMonth = dayjs(dayWisePerformance?.select_Month).format('MMMM YYYY');
          if (csvMonth !== selectedMonth) {
            Swal.fire({
              icon: 'error',
              title: 'Data Mismatch',
              text: `Error: The data month from the CSV (${csvMonth}) does not match the selected month (${selectedMonth}). Please upload data for the selected month.`,
            });
            return;
          }
          const numDaysInSelectedMonth = dayjs(dayWisePerformance?.select_Month).daysInMonth();
          if (parsedData?.length !== numDaysInSelectedMonth) {
            Swal.fire({
              icon: 'error',
              title: 'Data Length Mismatch',
              text: `Error: The CSV contains ${parsedData?.length} days, but the selected month (${selectedMonth}) has ${numDaysInSelectedMonth} days. Please ensure the data matches.`,
            });
            return;
          }

          const updatedDays = parsedData?.reverse()?.map((row, index) => {
            const parseNumber = (value) => {
              if (typeof value === "string") {
                return parseFloat(value.replace(/,/g, "") || 0);
              }
              return parseFloat(value || 0);
            };

            return {
              day: index + 1,
              opening: parseNumber(row["Open"] || 0),
              closing: parseNumber(row["Price"] || 0),
              highest: parseNumber(row["High"] || 0),
              lowest: parseNumber(row["Low"] || 0),
              percentage: parseNumber(row["Change %"] || 0),
            };
          });
          setFieldValue("dayWisePerformance.select_days", updatedDays);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };



  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
              mb: "8px",
            }}
          >
            Select Month
          </Typography>
          {isLoading ? (
            <Skeleton height={54} />
          ) : (
            <CustomYearMonthPicker
              InputProps={{
                sx: {
                  border: "none",
                  "& .MuiFormControl-root": { border: "none" },
                },
              }}
              value={dayWisePerformance?.select_Month}
              maxDate={dayjs().subtract(1, "month").endOf("month")}
              inputFormat={"MM/YYYY"}
              name="select_Month"
              onChange={(newValue) => {
                setFieldValue("dayWisePerformance.select_Month", newValue);
                setFieldValue(
                  "dayWisePerformance.select_days",
                  Array.from({ length: getDaysInMonth(newValue) }, (_, i) => ({
                    day: i + 1,
                    percentage: 0,
                  }))
                );
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
              mb: "8px",
            }}
          >
            Upload CSV File
          </Typography>
          <Button
            variant="contained"
            component="span"
            onClick={() => fileInputRef.current.click()}
            sx={{
              background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
              color: "#fff", textTransform: "none", fontSize: "16px", fontWeight: "400", padding: "10px 20px"
            }}
          >
            Upload CSV
          </Button>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVUpload}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
              mb: "4px",
            }}
          >
            Monthly (%)
          </Typography>
          <Typography
            sx={{
              color: memoizedTotalMonthlyPercentage < 0 ? "red" : "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              `${memoizedTotalMonthlyPercentage.toFixed(2)} %`
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
              mb: "4px",
            }}
          >
            Day Average (%)
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              `${(
                memoizedTotalMonthlyPercentage /
                dayWisePerformance?.select_days?.length
              )?.toFixed(10)} %`
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
              mb: "4px",
            }}
          >
            Net Balance
          </Typography>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(netBalance)
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
              mb: "4px",
            }}
          >
            Profit Loss Month
          </Typography>
          <Typography
            sx={{
              color:
                memoizedProfitLoss < 0
                  ? "red"
                  : memoizedProfitLoss > 0
                  ? "green"
                  : "inherit",
              fontWeight: "400",
              fontSize: { xs: "16px", sm: "18px" },
            }}
          >
            {isLoading ? (
              <Skeleton height={54} />
            ) : (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(memoizedProfitLoss)
            )}
          </Typography>
        </Grid>
      </Grid>

      <TableContainer
        sx={{
          marginTop: "20px",
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead
            sx={{
              background:
                "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Days
              </TableCell>
              {["Opening", "Closing", "Highest", "Lowest", "Percentage"].map(
                (header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {dayWisePerformance?.select_days?.map((day, i) => (
            <DayRow key={i} i={i} day={day} updateDayData={updateDayData} />
          ))}
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default DayWisePerformance;