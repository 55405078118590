import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import moment from "moment";
import BarChartIcon from "@mui/icons-material/BarChart";
import MonthlyCandelChartModel from "./MonthlyCendelChartModel";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import dayjs from "dayjs";
import YearPicker from "../../../../Components/YearPicker/YearPicker";
import { dolorFormatAmount } from "../../../../Utils/Services";
import DOCUMENT_ICON from "../../../../Assets/Images/Common/documentation.png";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import Logo from '../../../../Assets/Images/Common/pdf_logo.png';
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const MonthlyGraph = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [monthlyGraphList, setMonthlyGraphList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [performanceShowModel, setPerformanceShowModel] = useState(false);
  const [performanceGraphDetails, setPerformanceGraphDetails] = useState({});
  const [searchCustmer, setSearchCustmer] = useState("");
  const debouncedSearch = useDebounce(searchCustmer, 500);
  const [seletedMonthYear, setSelectedMonthYear] = React.useState(null);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No.",
      minWidth: 100,
      hideOnMobile: true,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {params?.row?.rowid}
          </Typography>
        );
      },
    },
    {
      field: "user_name",
      headerName: "Name",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {params?.row?.user_name}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      hideOnMobile: true,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {params?.row?.email}
          </Typography>
        );
      },
    },
    {
      field: "start_date",
      headerName: "Month Date",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        const { start_date } = params?.row;
        const seconds = start_date?.seconds;
        const nanoseconds = start_date?.nanoseconds;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        const date = moment(milliseconds);
        return <div>{date.format("MMMM Do YYYY, h:mm:ss A")}</div>;
      },
    },
    {
      field: "gross_performance_percentage",
      headerName: "Performance",
      minWidth: 120,
      renderCell: (params) => {
        const data = `${params?.row?.gross_performance_percentage?.toFixed(2) || 0}%`;
        return data;
      },
    },
    {
      field: "fiddux_commission",
      headerName: "Fiddux Commission",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const data = params?.row?.fiddux_commission;
        return <>{data ? "$" + dolorFormatAmount(data) : "-"}</>;
      },
    },
    {
      field: "total_Monthly_percantage",
      headerName: "Total Monthly Percentage",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const data = `${params?.row?.total_Monthly_percantage?.toFixed(2) || 0}%`;
        return data;
      },
    },
    {
      field: "profit_to_user",
      headerName: "Profit To User",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const data = params?.row?.profit_to_user;
        return <>{data ? "$" + dolorFormatAmount(data) : "-"}</>;
      },

    },
    {
      field: "isNotify",
      headerName: "PDF Documents",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpenPDFDocument(params?.row)}>
            <img src={DOCUMENT_ICON} alt="pdfFile" style={{ width: "50px" }} />
          </IconButton>
        );
      },
    },
    {
      field: "graph_points",
      headerName: "Graph",
      minWidth: 20,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
            onClick={() => handleShowHideMonthlyGraph(params?.row)}
          >
            <BarChartIcon />
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 50,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { isNotify } = params?.row;
        const actionButtons = [];
        actionButtons.push(
          <>
            <Tooltip title="Send PDF">
              <IconButton
                sx={{
                  background: "#4caf50",
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  "&:hover": { background: "#388e3c" },
                }}
                onClick={() => handleSendPDFMail(params?.row)}
              >
                <CheckIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </>
        );
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {actionButtons}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getMonthlyList(page, pageSize, debouncedSearch);
  }, [page, debouncedSearch, seletedMonthYear]);
  const getMonthlyList = async (
    currentPage = 0,
    pageSize = 50,
    debouncedSearch = ""
  ) => {
    setIsLoading(true);

    let q = query(
      collection(DATABASE, "monthly_grpahs"),
      orderBy("graph_month_date", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const transactions = querySnapshot?.docs?.map((doc, index) => ({
          rowid: index + 1,
          id: doc?.id,
          ...doc?.data(),
        }));

        const usersSnapshot = await getDocs(collection(DATABASE, "users"));
        const usersList = usersSnapshot?.docs?.map((userDoc) => ({
          doc_id: userDoc?.id,
          email: userDoc?.data()?.email,
          registered_date: userDoc?.data()?.registered_date,
          name: userDoc?.data()?.full_name,
          ...userDoc?.data(),
        }));

        const monthlyWithUserNames = transactions?.map((elem) => {
          const matchedUser = usersList.find(
            (cust) => cust?.doc_id === elem?.user_id
          );
          return {
            ...elem,
            user_name: matchedUser?.full_name || "Unknown",
            email: matchedUser?.email || "--",
            registered_date: matchedUser?.registered_date || "--",
          };
        });
        const filteredMonthly = debouncedSearch
          ? monthlyWithUserNames?.filter(
            (elem) =>
              elem?.email
                .toLowerCase()
                .includes(debouncedSearch.toLowerCase()) ||
              elem?.user_name
                .toLowerCase()
                .includes(debouncedSearch.toLowerCase())
          )
          : monthlyWithUserNames;
        const filteredByMonthYear = seletedMonthYear
          ? filteredMonthly?.filter((elem) => {
            const date = moment(
              elem?.graph_month_date?.seconds * 1000 +
              Math.floor(elem?.graph_month_date?.nanoseconds / 1000000)
            );
            return (
              date.month() === dayjs(seletedMonthYear).month() &&
              date.year() === dayjs(seletedMonthYear).year()
            );
          })
          : filteredMonthly;

        const paginatedMonthly = filteredByMonthYear?.slice(
          currentPage * pageSize,
          (currentPage + 1) * pageSize
        );

        setMonthlyGraphList(paginatedMonthly);
        setTotalCount(filteredByMonthYear?.length);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching deposits:", error);
        setIsLoading(false);
      }
    );
    return unsubscribe;
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    getMonthlyList(0, newPageSize, debouncedSearch);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    getMonthlyList(newPage, pageSize, debouncedSearch);
  };
  const handleShowHideMonthlyGraph = (graphPoints) => {
    setPerformanceShowModel(true);
    setPerformanceGraphDetails(graphPoints);
  };
  const handleOpenPDFDocument = async (graphPoints) => {

    const userId = graphPoints?.user_id;
    const reportMonth = moment(graphPoints?.graph_month_date.toDate());

    try {
      const userDoc = await getDoc(doc(DATABASE, "users", userId));
      if (!userDoc.exists) {
        console.log(`No user data found for ID: ${userId}`);
        return;
      }
      const user = userDoc?.data();
      if (!user) {
        console.log(`Invalid user data for ID: ${userId}`);
        return;
      }

      const grossPerformancePercentage = graphPoints?.gross_performance_percentage || 0;
      const percentageAfterCommission = dolorFormatAmount(graphPoints?.profit_to_user || 0);

      const updatedBalance = Number(user?.net_balance);
      const formattedUpdatedBalance = dolorFormatAmount(updatedBalance);

      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([600, 700]);
      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const logoBytes = await fetch(Logo).then((res) => res.arrayBuffer());
      const logoImage = await pdfDoc.embedPng(logoBytes);
      const logoWidth = 200;
      const logoHeight = 47;

      const title = "FIDUSFINANCE OÜ";
      const subtitle = `Monthly Report – ${reportMonth?.format("MMMM YYYY")}`;
      const clientName = `Mr. ${user?.full_name}`;
      const reportContent = `In alignment with our dedication to market analysis and transparency, FIDDUX is pleased to present its monthly performance report, which is based on extensive technical analysis and strategic positioning within the digital assets markets. Our committed team diligently continues to monitor and assess market trends to provide our clients with the best results and outcomes.`;
      const startDate = reportMonth.startOf('month').format("DD/MM/YYYY");
      const endDate = reportMonth.endOf('month').format("DD/MM/YYYY");

      const overallPerformance = `Overall performance: ${grossPerformancePercentage?.toFixed(2)}%`;
      const accountPerformance = `Account performance: ${percentageAfterCommission} USDT`;
      const updatedBalanceText = `Updated account balance: ${formattedUpdatedBalance} USDT`;

      const address = "FidusFinance OÜ - 16290879";
      const city = "Jõe tn 2a Tallinn Harjumaa 10151";
      const email = "order@fiddux.com";

      page.drawImage(logoImage, { x: 200, y: 600, width: logoWidth, height: logoHeight });
      page.drawText(title, { x: 50, y: 520, size: 16, font, color: rgb(0, 0, 0) });
      page.drawText(subtitle, { x: 50, y: 500, size: 14, font, color: rgb(0, 0, 0) });
      page.drawText(clientName, { x: 50, y: 480, size: 12, font, color: rgb(0, 0, 0) });
      page.drawText(reportContent, { x: 50, y: 420, size: 12, font, color: rgb(0, 0, 0), maxWidth: 500, lineHeight: 21 });
      page.drawText(`Start date: ${startDate}`, { x: 50, y: 300, size: 12, font, color: rgb(0, 0, 0) });
      page.drawText(`End date: ${endDate}`, { x: 50, y: 280, size: 12, font, color: rgb(0, 0, 0) });
      page.drawText(overallPerformance, { x: 50, y: 260, size: 12, font, color: rgb(0, 0, 0) });
      page.drawText(accountPerformance, { x: 50, y: 240, size: 12, font, color: rgb(0, 0, 0) });
      page.drawText(updatedBalanceText, { x: 50, y: 220, size: 12, font, color: rgb(0, 0, 0) });
      const drawCenteredText = (text, yPosition, fontSize) => {
        const textWidth = font.widthOfTextAtSize(text, fontSize);
        const pageWidth = page.getWidth();
        const xPosition = (pageWidth - textWidth) / 2;
        page.drawText(text, {
          x: xPosition,
          y: yPosition,
          size: fontSize,
          font: font,
          color: rgb(0, 0, 0),
        });
      };
      drawCenteredText(address, 110, 12);
      drawCenteredText(city, 90, 12);
      drawCenteredText(email, 70, 12);
      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  const handleSendPDFMail = async (graphPoints) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `would you like to send the report to ${graphPoints?.user_name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const selectedMonth = moment(graphPoints?.graph_month_date.toDate());
        const matchingEntries = []
        matchingEntries.push(graphPoints);
        const isNotify = [];
        isNotify.push(graphPoints?.user_id);
        axios.post(
          'https://sendpdfmonthlyperformance-lq7gauxrna-uc.a.run.app', // Production mode,
          // "https://generateandsendpdf-f2yyu737lq-uc.a.run.app", // Devlopment mode,
          { matchingEntries, isNotify, selectedMonth },
          { headers: { 'Content-Type': 'application/json' } }
        );
        getMonthlyList();
        toast.success(`You've successfully sent the monthly PDF to ${graphPoints?.user_name}`);
      } catch (error) {
        console.log('error', error);
        toast.error(`PDF couldn't be sent to ${graphPoints?.user_name} due to (error). Please try again after sometime`);
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingTop: { xs: '60px', sm: "0px" }
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Monthly Graph Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => navigate("/admin/add-monthly-graph")}
                sx={{
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  color: "#fff",
                  height: "46px",
                  padding: "0px 20px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#fff",
                  },
                }}
              >
                + Create Monthly Graph
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={3} alignItems={'center'}>
              <Grid item xs={12} md={6}>
                <CustomSearch
                  placeholder="Search Customer (Email, Full Name)"
                  value={searchCustmer}
                  onChange={(event) => setSearchCustmer(event.target.value)}
                  onClear={() => setSearchCustmer("")}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ width: '300px', display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{
                  width: '300px'
                }}>
                  <YearPicker
                    value={seletedMonthYear}
                    onChange={(newValue) => setSelectedMonthYear(newValue)}
                    placeholder="Select Month"
                    InputProps={{
                      sx: {
                        height: 40,
                        border: "none",
                        "& .MuiFormControl-root": { border: "none" },
                      },
                    }}
                    name="select_Month"
                  />
                </Box>

              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TableContainer
              rows={monthlyGraphList}
              columns={columns}
              loading={isLoading}
              page={page}
              pageSize={pageSize}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              paginationShow={true}
            />
          </Grid>
        </Grid>
      </Box>
      {performanceShowModel && (
        <MonthlyCandelChartModel
          open={performanceShowModel}
          onClose={() => setPerformanceShowModel(false)}
          handleOnClose={() => setPerformanceShowModel(false)}
          performanceGraphDetails={performanceGraphDetails}
        />
      )}
    </>
  );
};

export default MonthlyGraph;
