import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const CustomYearMonthPicker = ({
  value,
  onChange,
  sx = {},
  name,
  InputProps,
  placeholder
}) => {
  const previousMonth = dayjs().subtract(1, "month");
  const maxDate = previousMonth.endOf("month");
  const validValue = 
    !value || dayjs(value).isAfter(maxDate) ? previousMonth : dayjs(value);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["year", "month"]}
        value={validValue}
        onChange={onChange}
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          ...sx,
        }}
        InputProps={InputProps}
        name={name}
        maxDate={maxDate} 
        placeholder={placeholder}
      />
    </LocalizationProvider>
  );
};

export default CustomYearMonthPicker;
