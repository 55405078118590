import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import ShowYearlyGraph from "./ShowYearlyGraph";
import Skeleton from "react-loading-skeleton";
const YearlyGraph = () => {
    const [monthlyGraphList, setMonthlyGraphList] = useState([]);
    const [avarage, setAvarage] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getMonthlyList();
    }, []);
    const getMonthlyList = async () => {
        setIsLoading(true);
        let q = query(collection(DATABASE, "yearly_graph"), orderBy('created_at', "desc"), limit(12));
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const transactions = querySnapshot?.docs?.map((doc, index) => ({
                ...doc.data(),
            }));
            const total = transactions?.reduce((total, item) => total + item?.amount, 0);
            setAvarage(total)
            setMonthlyGraphList(transactions);
            setIsLoading(false);

        }, (error) => {
            console.error("Error fetching yearly:", error);
            setIsLoading(false);
        });
        return unsubscribe;
    }
    return (
        <>
            <Box sx={{
                width: "100%"
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography sx={{ width:'100%', fontSize: "24px", fontWeight: "500", marginBottom: '20px',marginTop: { xs: '50px', sm:"0px", md: '0px' }  }}>
                            Last 12 Month Performance
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    
                        {isLoading ? <Skeleton height={400} /> : <ShowYearlyGraph
                            performanceGraphDetails={monthlyGraphList}
                            avarage={avarage}
                        />}
                    </Grid>
                    
                </Grid>
            </Box>


        </>
    );
};

export default YearlyGraph;