import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { DATABASE } from '../../../Config/firebase';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const ViewLedgersModel = (props) => {
    const { open, onClose, selectCustomerLedgersID } = props;
    const [ledgers, setLedgers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const getLedgersList = () => {
        setIsLoading(true);
        const q = query(
            collection(DATABASE, "ledgers"),
            orderBy('date', "desc"),
            where("user_id", "==", selectCustomerLedgersID)
        );
        return onSnapshot(q, (querySnapshot) => {
            const allData = querySnapshot?.docs
                .filter(doc => doc?.data())
                .map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
            setLedgers(allData);
            setIsLoading(false);
        }, (error) => {
            console.error("Error fetching ledgers:", error);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (selectCustomerLedgersID) {
            getLedgersList();
        }
    }, [selectCustomerLedgersID]);

    const latestNetBalance = ledgers?.length > 0 ? (parseFloat(ledgers?.[0]?.current_balance) || parseFloat(ledgers?.[0]?.balance) || 0) : 0;
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    height: "600px",
                    overflow: "scroll",
                    "::-webkit-scrollbar": { width: "0px", height: '0px' },
                },
                "& .MuiDialogContent-root": {
                    overflowY: "initial",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }
            }}
        >
            <DialogTitle>Customer Ledgers</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Skeleton width={80} /></TableCell>
                                    <TableCell><Skeleton width={80} /></TableCell>
                                    <TableCell><Skeleton width={80} /></TableCell>
                                    <TableCell><Skeleton width={80} /></TableCell>
                                    <TableCell><Skeleton width={80} /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...Array(5)].map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell><Skeleton /></TableCell>
                                        <TableCell><Skeleton width={60} /></TableCell>
                                        <TableCell><Skeleton width={60} /></TableCell>
                                        <TableCell><Skeleton width={80} /></TableCell>
                                        <TableCell><Skeleton width={80} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <>
                        <TableContainer sx={{
                                height: '320px',
                                overflowY: 'auto'
                            }}>
                            <Table >
                                <TableHead sx={{
                                    background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                                    fontFamily: "Outfit",
                                    borderRadius: '10px',
                                    textAlign: "center",
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1 
                                }}>
                                    <TableRow>
                                        <TableCell sx={{ color: "#fff", }}>Date</TableCell>
                                        <TableCell sx={{ color: "#fff", }}>Debit</TableCell>
                                        <TableCell sx={{ color: "#fff", }}>Credit</TableCell>
                                        <TableCell sx={{ color: "#fff", }}>Profit (%)</TableCell>
                                        <TableCell sx={{ color: "#fff", }}>Performance</TableCell>
                                        <TableCell sx={{ color: "#fff", }}>Balance</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ledgers?.length > 0 ? (
                                        ledgers?.map((ledger) => (
                                            <TableRow key={ledger?.id}>
                                                <TableCell>
                                                    {(() => {
                                                        const { date } = ledger;
                                                        if (date) {
                                                            const seconds = date?.seconds;
                                                            const nanoseconds = date?.nanoseconds;
                                                            const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
                                                            return moment(milliseconds).format('MMMM Do YYYY HH:mm:ss A');
                                                        }
                                                        return 'N/A';
                                                    })()}
                                                </TableCell>
                                                <TableCell>{ledger?.transection_type === 2 ? formatCurrency(ledger?.new_txn_amount || ledger?.amount) : "--"}</TableCell>
                                                <TableCell>{ledger?.transection_type === 0 ? formatCurrency(ledger?.new_txn_amount || ledger?.amount) : "--"}</TableCell>
                                                <TableCell>
                                                    {ledger?.profit_loss_percent != null
                                                        ? `${ledger?.profit_loss_percent?.toFixed(4)}%`
                                                        : "0.00 %"}
                                                </TableCell>

                                                <TableCell sx={{
                                                    color: ledger?.profit_loss_balance < 0 ? 'red' :
                                                        ledger?.profit_loss_balance > 0 ? 'green' : 'inherit'

                                                }}>{formatCurrency(ledger?.profit_loss_balance || 0)}</TableCell>
                                                <TableCell>{formatCurrency(ledger?.current_balance || ledger?.balance || 0)}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography variant="body1" align="center">No ledgers found for this customer.</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginTop: '24px',
                        }}>
                            Net Balance:
                            <DialogTitle> {formatCurrency(latestNetBalance)}</DialogTitle>
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ViewLedgersModel;
