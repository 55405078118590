import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import { adminLayoutRoute } from "../../Routes/Routes";
const AdminLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userCheck = localStorage.getItem("access_token");
    const roleCheck = localStorage.getItem("role");
    if (!userCheck && !roleCheck) {
      navigate("/login");
    }
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Routes>
        {adminLayoutRoute?.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={
                route.sidebar ? (
                  <>
                    <Sidebar />
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          md: "calc(100% - 240px)",
                        },
                        height: "100vh",
                        overflow: "auto",
                        p: 1,
                        bgcolor: "#fff",
                      }}
                    >
                      {route.component}
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{ width: "100%", height: "100vh", overflow: "auto"}}
                  >
                    {route.component}
                  </Box>
                )
              }
            />
          );
        })}
      </Routes>
    </Box>
  );
};
export default AdminLayout;
