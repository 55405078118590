import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Box } from "@mui/material";

const YearPicker = ({
    value,
    onChange,
    sx = {},
    name,
    InputProps,
    placeholder
}) => {
    const previousMonth = dayjs().subtract(1, "month");
    const maxDate = previousMonth.endOf("month");
    const validValue = !value || dayjs(value).isAfter(maxDate) ? null : dayjs(value);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                views={["year", "month"]}
                value={validValue}
                onChange={onChange}
                sx={{
                    width: "100%",
                    backgroundColor: "#fff",
                    ...sx,
                }}
                InputProps={InputProps}
                name={name}
                maxDate={maxDate}
                placeholder={placeholder}
            />
        </LocalizationProvider>
    );
};

export default YearPicker;
