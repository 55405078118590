import React from "react";
import { Chart as ChartJS, TimeScale, Title, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import moment from "moment";
import {
  CandlestickController,
  CandlestickElement,
} from "chartjs-chart-financial";
ChartJS.register(
  TimeScale,
  Title,
  Tooltip,
  Legend,
  CandlestickController,
  CandlestickElement
);

const PerformanceDailyGraph = ({
  multipleUsersEntryList,
}) => {
  const graphPoints = multipleUsersEntryList?.graph_points || [];
  const candleData = graphPoints
    .map((point) => {
      const {
        date,
        open,
        close,
        high,
        low,
        volume,
      } = point;
      const timestamp = date?.seconds
        ? moment(date.seconds * 1000).valueOf()
        : null;

      if (timestamp === null) {
        return null;
      }

      return {
        x: timestamp,
        o: open,
        h: high,
        l: low,
        c: close,
      };
    })
    .filter((dataPoint) => dataPoint !== null);

  const data = {
    datasets: [
      {
        label: "Candle Chart",
        data: candleData,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        backgroundColor: (context) => {
          const { dataIndex } = context;
          const currentData = candleData[dataIndex];
          return currentData?.c > currentData?.o
            ? "rgba(75, 192, 192, 1)"
            : "rgba(255, 99, 132, 1)";
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "MMM d, yyyy",
          displayFormats: {
            day: "MMM d, yyyy",
          },
        },
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Price",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `O: ${tooltipItem?.raw?.o?.toFixed(
              2
            )}, H: ${tooltipItem?.raw?.h?.toFixed(
              2
            )}, L: ${tooltipItem?.raw?.l?.toFixed(
              2
            )}, C: ${tooltipItem?.raw?.c?.toFixed(2)}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Chart type="candlestick" data={data} options={options} />
    </div>
  );
};

export default PerformanceDailyGraph;
