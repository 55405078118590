import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { DATABASE } from "../../../../Config/firebase";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CustomDropDown from "../../../../Components/CustomDropDown/CustomDropDown";
import TableContainer from "../../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import CustomSearch from "../../../../Components/CustomSearch/CustomSearch";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import PaidIcon from "@mui/icons-material/Paid";
import FullWithdrawModel from "./FullWithdrawModel";
import moment from "moment";
const WithdrowFunds = () => {
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [withdrowStatus, setWithdrowStatus] = useState(null);
  const [withdrowList, setWithdrowList] = useState([]);
  const [searchWithdraw, setSearchWithdraw] = useState("");
  const debouncedSearch = useDebounce(searchWithdraw, 500);
  const [isFullWithdrawModel, setIsFullWithdrawModel] = useState(false);
  const [offerAmountDetails, setOfferAmountDetails] = useState({});
  const withdrowStatusList = [
    {
      id: null,
      name: "All",
    },
    {
      id: 0,
      name: "Pending",
    },
    {
      id: 2,
      name: "Mark As Paid",
    },
    {
      id: 3,
      name: "Cancelled - By User",
    },
    {
      id: 4,
      name: "Rejected",
    },
    {
      id: 5,
      name: "Paid",
    },
  ];
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100,hideOnMobile: true, },
    {
      field: "user_name",
      headerName: "Name",
      minWidth: 200,
      renderCell: (params) => <span>{params?.row?.user_name || "--"}</span>,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => <span>{params?.row?.email || "--"}</span>,
    },
    {
      field: "total_amount",
      headerName: "Amount",
      minWidth: 200,
      renderCell: (params) => {
        const balance = params?.row?.total_amount || 0;
        const is_full_withdraw = params?.row?.is_full_withdraw;
        const formattedBalance = new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(balance);

        return (
          <Tooltip title={formattedBalance}>
            <span>
              {is_full_withdraw
                ? `$${formattedBalance} (Full Withdraw)`
                : `$${formattedBalance}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      minWidth: 200,
      filterable: false,
      editable: false,
      sortable: false,
      hideOnMobile: true,
      disableColumnMenu: true,
      renderCell: (params) => <span>{`${params?.row?.notes}` || "--"}</span>,
    },
    {
      field: "date",
      headerName: "Registered Date",
      minWidth: 250,
      renderCell: (params) => {
        const { date } = params?.row;
        const seconds = date?.seconds;
        const nanoseconds = date?.nanoseconds;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        const dateP = moment(milliseconds);
        return <div>{dateP.format("DD/MM/YYYY hh:mm A")}</div>;
      },
    },
    {
      field: "txn_id",
      headerName: "TXN",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => <span>{`${params?.row?.txn_id}` || "--"}</span>,
    },
    {
      field: "request_status",
      headerName: "Status",
      minWidth: 200,
      renderCell: (params) => {
        const { request_status } = params?.row;
        const { profit_loss_percent } = params?.row;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "10px",
              position: "relative",
            }}
          >
            {request_status === 0 ? (
              <>
                <Chip
                  label="OTP verification Pending"
                  sx={{
                    color: "#fff",
                    backgroundColor: "orange",
                    cursor: "pointer",
                  }}
                />
              </>
            ) : request_status === 1 && profit_loss_percent !== 0 ? (
              <>
                <Chip
                  label="Awaiting User Action"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#F44336",
                    cursor: "pointer",
                  }}
                />
              </>
            ) : request_status === 1 && profit_loss_percent === 0 ? (
              <>
                <IconButton
                  sx={{
                    background: "#4caf50",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "#388e3c",
                    },
                  }}
                  onClick={() => {
                    if (params?.row?.is_full_withdraw) {
                      handleAcceptRejectOfferAmount(params?.row);
                    } else {
                      handleAcceptRejectOnChange(params?.row, 2);
                    }
                  }}
                >
                  <CheckIcon sx={{ color: "#fff" }} />
                </IconButton>
                <IconButton
                  sx={{
                    background: "red",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "red",
                    },
                  }}
                  onClick={() => handleAcceptRejectOnChange(params?.row, 4)}
                >
                  <CloseIcon sx={{ color: "#fff" }} />
                </IconButton>
              </>
            ) : (
              ""
            )}
            {request_status === 2 && (
              <>
                <IconButton
                  sx={{
                    background: "#4caf50",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "#388e3c",
                    },
                  }}
                  onClick={() => handleAcceptRejectOnChange(params?.row, 5)}
                >
                  <PaidIcon
                    sx={{
                      color: "#fff",
                    }}
                  />
                </IconButton>
              </>
            )}
            {request_status === 3 && (
              <Chip
                label="Cancelled By User"
                sx={{
                  color: "#fff",
                  backgroundColor: "#F44336",
                  cursor: "pointer",
                }}
              />
            )}
            {request_status === 4 && (
              <Chip
                label="Rejected"
                sx={{
                  color: "#fff",
                  backgroundColor: "#D32F2F",
                  cursor: "pointer",
                }}
              />
            )}
            {request_status === 5 && (
              <Chip
                label="Paid"
                sx={{
                  background: "#4caf50",
                  color: "#fff",
                  "&:hover": {
                    background: "#388e3c",
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const unsubscribe = getWithdrow(
      page,
      pageSize,
      withdrowStatus,
      debouncedSearch
    );
    return () => unsubscribe();
  }, [page, withdrowStatus, debouncedSearch]);
  const getWithdrow = (
    currentPage = 0,
    pageSize = 50,
    withdrawStatus = null,
    debouncedSearch = ""
  ) => {
    setIsLoading(true);
    let q = query(
      collection(DATABASE, "transection_requests"),
      where("request_type", "==", 1),
      where("otp_verify", "==", true),
      orderBy("date", "desc")
    );
    if (withdrawStatus !== null) {
      q = query(q, where("request_status", "==", withdrawStatus));
    }
    const unsubscribe = onSnapshot(
      q,
      async (querySnapshot) => {
        const transactions = querySnapshot?.docs?.map((doc, index) => ({
          rowid: index + 1,
          id: doc?.id,
          ...doc?.data(),
        }));
        const usersSnapshot = await getDocs(collection(DATABASE, "users"));
        const usersList = usersSnapshot?.docs?.map((userDoc) => ({
          doc_id: userDoc?.id,
          email: userDoc?.data()?.email,
          name: userDoc?.data()?.name || userDoc?.data()?.full_name,
          net_balance: userDoc?.data()?.net_balance,
          ...userDoc?.data(),
        }));
        const transactionsWithUserNames = transactions.map((transaction) => {
          const matchedUser = usersList.find(
            (user) => user?.doc_id === transaction?.user_id
          );
          return {
            ...transaction,
            withdraw_approved_by: matchedUser?.name,
            user_name: matchedUser?.name || "Unknown",
            email: matchedUser?.email || "--",
            net_balance: matchedUser?.net_balance || 0,
          };
        });
        const filteredTransactions = debouncedSearch
          ? transactionsWithUserNames?.filter(
              (transaction) =>
                transaction?.email
                  .toLowerCase()
                  .includes(debouncedSearch.toLowerCase()) ||
                transaction?.user_name
                  .toLowerCase()
                  .includes(debouncedSearch.toLowerCase())
            )
          : transactionsWithUserNames;

        const startIndex = currentPage * pageSize;
        const paginatedTransactions = filteredTransactions?.slice(
          startIndex,
          startIndex + pageSize
        );
        setWithdrowList(paginatedTransactions);
        setTotalCount(filteredTransactions.length);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching withdrawals:", error);
        setIsLoading(false);
      }
    );
    return unsubscribe;
  };

  const getTimestamp = () => {
    const currentDate = moment.utc();
    const hour = currentDate.hour();

    if (hour >= 21) {
      currentDate.add(1, "day").startOf("day");
    }

    return currentDate.toDate();
  };

  const handleAcceptRejectOnChange = async (Data, Status) => {
    setIsLoading(true)
    const actionText =
      Status === 2
        ? "approve"
        : Status === 4
        ? "reject"
        : Status === 5
        ? "Mark As Paid"
        : "";
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You want to ${actionText} this withdraw`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        if (!Data?.user_id) {
          throw new Error("User ID is missing");
        }
        let amountToDeduct;
        if (Status === 4) {
          const userDoc = await getDoc(doc(DATABASE, "users", Data?.user_id));
          if (!userDoc.exists()) {
            throw new Error("User does not exist");
          }
          const currentBalance = userDoc?.data()?.net_balance || 0;
          let amountToDeduct = parseFloat(Data?.total_amount) || 0;
          const newBalance = currentBalance + amountToDeduct;
          await updateDoc(doc(DATABASE, "users", Data?.user_id), {
            net_balance: newBalance,
          });
        }
        if (Status === 5 && Data?.full_withdraw_paid === 0) {
          const updateData = {
            request_status: Status,
            request_approval_time: serverTimestamp(),
            full_withdraw_paid: Data?.is_full_withdraw === true ? 1 : 0,
          };
          const userDoc = await getDoc(doc(DATABASE, "users", Data?.user_id));
          if (!userDoc.exists()) {
            throw new Error("User does not exist");
          }
          const currentBalance = userDoc?.data()?.net_balance || 0;
          const newBalance = currentBalance;
          amountToDeduct = Data?.offered_amount
            ? parseFloat(Data?.offered_amount)
            : parseFloat(Data?.total_amount);

          const ledgerEntry = {
            new_txn_amount: amountToDeduct,
            user_id: Data?.user_id,
            date: getTimestamp(),
            previous_balance: Data?.original_balance,
            current_balance: newBalance,
            transection_type: 2,
            doc_id: Date.now().toString(),
            profit_loss_percent: 0,
            profit_loss_balance: 0,
          };
          await updateDoc(
            doc(DATABASE, "transection_requests", Data?.doc_id),
            updateData
          );
          await setDoc(
            doc(DATABASE, "ledgers", ledgerEntry?.doc_id),
            ledgerEntry
          );
        } else if (Status !== 5) {
          const updateData = {
            request_status: Status,
            request_approval_time: Timestamp.now(),
          };
          await updateDoc(
            doc(DATABASE, "transection_requests", Data.doc_id),
            updateData
          );
        }
        getWithdrow(page, pageSize, withdrowStatus);
        const successMessages = {
          2: "Withdraw request approved successfully!",
          4: "Withdraw request rejected.",
          5: "Withdraw request marked as paid.",
        };
        const message =
          successMessages[Status] || "Withdraw status updated successfully!";
        toast.success(message, { autoClose: 3000 });
      } catch (error) {
        console.error("Error updating withdraw status:", error);
        toast.error(error.message || "Something went wrong. Try again!", {
          autoClose: 3000,
        });
      } finally{
        setIsLoading(false)
      }
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    getWithdrow(0, newPageSize, withdrowStatus);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    getWithdrow(newPage, pageSize, withdrowStatus);
  };
  const handleAcceptRejectOfferAmount = (params) => {
    setIsFullWithdrawModel(true);
    setOfferAmountDetails(params);
  };
  const handleOfferAmountOnSubmit = () => {
    setIsFullWithdrawModel(false);
    setOfferAmountDetails({});
  };
  // ========== End Pagination ==========
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          paddingTop:{xs:'60px', sm:"0px"}
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Withdraw Funds
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <CustomSearch
              placeholder="Search (Email, Full Name)"
              value={searchWithdraw}
              onChange={(event) => setSearchWithdraw(event.target.value)}
              onClear={() => setSearchWithdraw("")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <CustomDropDown
                    sx={{ width: "250px", height: "46px" }}
                    placeholder={"Select Withdraw"}
                    menuList={withdrowStatusList}
                    value={withdrowStatus}
                    onChange={(e) => {
                      setWithdrowStatus(e.target.value);
                      setPage(0);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            paddingTop: "24px",
          }}
        >
          <TableContainer
            rows={withdrowList}
            columns={columns}
            loading={isLoading}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            paginationShow={true}
          />
        </Box>
      </Box>
      <FullWithdrawModel
        open={isFullWithdrawModel}
        onClose={() => setIsFullWithdrawModel(false)}
        offerAmountDetails={offerAmountDetails}
        handleOfferAmountOnSubmit={handleOfferAmountOnSubmit}
      />
    </> 
  );
};

export default WithdrowFunds;