import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LineController,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend);

const ShowYearlyGraph = ({ performanceGraphDetails,avarage }) => {
    const graphPoints = performanceGraphDetails;
    if (!Array.isArray(graphPoints) || graphPoints.length === 0) {
        return null;
    }
    const reversedGraphPoints = [...graphPoints].reverse();

    const labels = reversedGraphPoints?.map(point => {
        const monthIndex = point.month - 1;
        return new Date(2024, monthIndex).toLocaleString('default', { month: 'long' });
    });
    const data = {
        labels: labels,
        datasets: [
            {
                label: `Performance ${avarage} %`,
                data: reversedGraphPoints?.map(point => {
                    const percentageValue = parseFloat(point?.point);
                    return isNaN(percentageValue) ? 0 : percentageValue;
                }),
                borderColor: '#00AD3C',
                backgroundColor: 'rgba(0, 173, 60, 0.2)',
                tension: 0.3,
                
                fill: true,
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 10,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: 16,
                        weight:600
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const percentageValue = parseFloat(reversedGraphPoints[tooltipItem?.dataIndex]?.point);
                        return `Percentage: ${percentageValue.toFixed(2)}%`;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Months',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                },
                ticks: {
                    callback: function (value) {
                        return `${value.toFixed(2)}%`;
                    },
                },
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default ShowYearlyGraph;