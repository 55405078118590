import React from "react";
import { Chart as ChartJS, TimeScale, Title, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import moment from "moment";
import {
  CandlestickController,
  CandlestickElement,
} from "chartjs-chart-financial";
import { Dialog } from "@mui/material";
ChartJS.register(
  TimeScale,
  Title,
  Tooltip,
  Legend,
  CandlestickController,
  CandlestickElement
);

const MonthlyCandleChartModel = ({
  performanceGraphDetails,
  open, onClose
}) => {
  const graphPoints = performanceGraphDetails?.graph_points || [];
  const candleData = graphPoints?.map((point) => {
    const {
      date,
      open,
      close,
      high,
      low,
      volume,
    } = point;
    const timestamp = date?.seconds
      ? moment(date.seconds * 1000).valueOf()
      : null;

    if (timestamp === null) {
      return null;
    }

    return {
      x: timestamp,
      o: open,
      h: high,
      l: low,
      c: close,
    };
  })
    .filter((dataPoint) => dataPoint !== null);

  const data = {
    datasets: [
      {
        label: "Monthly Graph",
        data: candleData,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        backgroundColor: (context) => {
          const { dataIndex } = context;
          const currentData = candleData[dataIndex];
          return currentData?.c > currentData?.o
            ? "rgba(75, 192, 192, 1)"
            : "rgba(255, 99, 132, 1)";
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "MMM d, yyyy",
          displayFormats: {
            day: "MMM d, yyyy",
          },
        },
        title: {
          display: true,
        },
      },
      y: {
        title: {
          display: true,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return [
              `Open: ${tooltipItem?.raw?.o?.toFixed(2)}`,
              `High: ${tooltipItem?.raw?.h?.toFixed(2)}`,
              `Low: ${tooltipItem?.raw?.l?.toFixed(2)}`,
              `Close: ${tooltipItem?.raw?.c?.toFixed(2)}`,
            ];
          },
        },
      },
    },
  };

  return (<Dialog
    open={open}
    onClose={onClose}
    maxWidth="lg"
    fullWidth
    sx={{
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: '20px 30px',
        overflow: "scroll",
        "::-webkit-scrollbar": { width: "0px", height: '0px' },
      },
      "& .MuiBackdrop-root": {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }
    }}
  >
    <Chart type="candlestick" data={data} options={options} />
  </Dialog>)
};

export default MonthlyCandleChartModel;
