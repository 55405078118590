import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import CustomTextField from "../../../../Components/CustomTextField/CustomTextField";
import CustomSubmitButton from "../../../../Components/CustomSubmitButton/CustomSubmitButton";
import { DATABASE } from "../../../../Config/firebase";
import { doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
const NewWithdrowModel = (props) => {
  const { open, onClose, handleOnClose, customerWithdraw, handleSubmited } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const getTimestamp = () => {
    const currentDate = moment.utc();
    const hour = currentDate.hour();
    if (hour >= 21) {
      currentDate.add(1, "day").startOf("day");
    }
    return currentDate.toDate();
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      notes: "",
      selectCustomerId: 0,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required("Amount is required"),
      notes: Yup.string().required("Notes are required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) return;
      else {
        if (
          customerWithdraw?.length > 0 &&
          customerWithdraw[0]?.net_balance >= values?.amount
        ) {
          try {
            const newAmount = parseFloat(values?.amount);
            const currentNetBalance = customerWithdraw[0]?.net_balance || 0;
            const withdrawDocId = Date.now();
            const withdrawDocId1 = Date.now().toString();
            const withdrawParams = {
              admin_commission: 0.0,
              date: Timestamp.now(),
              request_status: 5,
              request_type: 1,
              notes: values?.notes,
              payment_receipt: "",
              user_id: customerWithdraw[0]?.doc_id,
              remaining_balance: 0,
              profit_loss: 0,
              total_amount: newAmount,
              profit_loss_percent: 0,
              request_approval_time: Timestamp.now(),
              transection_fee: 0,
              txn_id: withdrawDocId,
              transaction_id:withdrawDocId1,
              withdraw_amount: newAmount,
              otp: "0",
              otp_verify: true,
              doc_id: withdrawDocId1,
            };
            const ledgers = {
              new_txn_amount: newAmount,
              previous_balance: customerWithdraw[0]?.net_balance,
              current_balance: currentNetBalance - newAmount,
              date: getTimestamp(),
              doc_id: Date.now().toString(),
              profit_loss_percent: 0,
              transection_type: 2,
              user_id: customerWithdraw[0]?.doc_id,
              profit_loss_balance: 0,
            };
            const updateNet_Balance = {
              net_balance: currentNetBalance - newAmount,
            };
            await Promise.all([
              await setDoc(
                doc(DATABASE, "transection_requests", Date.now().toString()),
                withdrawParams
              ),
              await updateDoc(
                doc(DATABASE, "users", customerWithdraw[0]?.doc_id),
                updateNet_Balance
              ),
              await setDoc(doc(DATABASE, "ledgers", ledgers?.doc_id), ledgers),
            ]);
            toast.success("WithDraw Updated successfully!");
            resetForm();
            handleSubmited();
            handleOnClose();
          } catch (error) {
            console.error("Error updating transaction or balance:", error);
            toast.error("An error occurred. Please try again.");
          } finally {
            setIsLoading(false);
          }
        } else {
          toast.error(
            `Insufficient balance to withdraw! Your current balance is ${customerWithdraw[0]?.net_balance}, but you are trying to withdraw ${values?.amount}.`
          );
          setIsLoading(false);
          resetForm();
        }
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "20px 30px",
          height: "450px",
          overflow: "scroll",
          "::-webkit-scrollbar": { width: "0px", height: "0px" },
        },
        "& .MuiDialogContent-root": {
          overflowY: "initial",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#101010",
                fontFamily: "Outfit",
              }}
            >
              Request Withdraw
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                sx={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#101010",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#101010",
                    color: "#fff",
                  },
                }}
                onClick={handleOnClose}
              >
                <CloseIcon sx={{ color: "#fff", fontSize: "20px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            padding: "20px 0px 0px 0px",
          }}
        >
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#101010",
                  fontFamily: "Outfit",
                }}
              >
                Net Balance:{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(customerWithdraw[0]?.net_balance)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Amount"
                type="number"
                min="0"
                max={1}
                inputMode="numeric"
                pattern="[0-9]*"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                placeholder="Enter Amount"
                name="amount"
                value={formik?.values?.amount}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                errors={formik?.touched?.amount && formik?.errors?.amount}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: "1px solid #CACACA",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label="Notes"
                type="text"
                placeholder="Enter your notes"
                name="notes"
                value={formik?.values?.notes}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                multiline={true}
                rows={3}
                errors={formik?.touched?.notes && formik?.errors?.notes}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: "1px solid #CACACA",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSubmitButton
                btnTitle={"Submit Request"}
                loading={isLoading}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

export default NewWithdrowModel;
