import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import { useNavigate } from "react-router-dom";
import {
  collection,
  updateDoc,
  doc,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import { DATABASE } from "../../../Config/firebase";
import useDebounce from "../../../Components/useDebounce/useDebounce";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DOCUMENT_ICON from "../../../Assets/Images/Common/documentation.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment/moment";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import CloseIcon from "@mui/icons-material/Close";
import ViewLedgersModel from "./ViewLedgersModel";
import { dolorFormatAmount } from "../../../Utils/Services";

const CustomerManagement = () => {
  const [pageSize, setPageSize] = useState(50);
  const navigate = useNavigate();
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchCustmer, setSearchCustmer] = useState("");
  const debouncedSearch = useDebounce(searchCustmer, 500);
  const [isStatus, setIsStatus] = useState(null);
  const [isCategoryActive, setIsCategoryActive] = useState(null);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [viewLedgersModel, setViewLedgersModel] = useState(false);
  const [selectCustomerLedgersID, setSelectCustomerLedgersID] = useState(null);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No.",
      minWidth: 100,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      hideOnMobile: true,
    },
    {
      hideOnMobile: true,
      field: "profile_image",
      headerName: "Profile",
      minWidth: 50,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params?.row?.profile_image ? (
          <img
            src={params?.row?.profile_image}
            alt="profile_photo"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "10px",
            }}
          />
        ) : (
          <AccountCircleIcon sx={{ fontSize: "40px" }} />
        );
      },
    },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 250,
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        const fullName = params?.row?.full_name || "--";
        return (
          <Tooltip title={fullName?.length > 25 ? fullName : ""}>
            <span>
              {fullName?.length > 25
                ? `${fullName?.substring(0, 25)}...`
                : fullName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 200,
      filterable: false,
      editable: false,
      sortable: false,
      hideOnMobile: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip
          title={
            params?.row?.phone
              ? `${params?.row?.phone.split(",")[1]} - ${params?.row?.phone
                .split(",")
                .pop()}`
              : "--"
          }
        >
          <span>
            {params?.row?.phone
              ? `${params?.row?.phone.split(",")[1]} - ${params?.row?.phone
                .split(",")
                .pop()}`
              : "--"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
      hideOnMobile: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.email || "--"}>
          <span>{params?.row?.email || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "user_type",
      headerName: "User Type",
      flex: 1,
      minWidth: 300,
      hideOnMobile: true,
      renderCell: (params) => (
        <span>
          {params?.row?.user_type === 0 ? "Individual Customer" : (params?.row?.user_type === 1 ? "Corporate Customer" : "--")}
        </span>
      ),
    },
    {
      field: "document",
      headerName: "Document",
      minWidth: 50,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      hideOnMobile: true,
      renderCell: (params) => {
        const { document, mediaType } = params?.row;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {mediaType === "image" ? (
              <Tooltip title={"Click to view document"}>
                <img
                  src={document}
                  alt="Uploaded document"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "10px",
                    objectFit: "cover",
                  }}
                  onClick={() => window.open(document, "_blank")}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Click to view document"}>
                <img
                  src={DOCUMENT_ICON}
                  alt="document"
                  style={{ width: "50px" }}
                  onClick={() => window.open(document, "_blank")}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      field: "document_number",
      headerName: "Document Number",
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => (
        <Tooltip title={params?.row?.document_number || "--"}>
          <span>{params?.row?.document_number || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "net_balance",
      headerName: "Net Balance",
      minWidth: 200,
      hideOnMobile: true,
      renderCell: (params) => {
        const balance = params?.row?.net_balance || 0;
        const formattedBalance = dolorFormatAmount(balance);

        return (
          <Tooltip title={formattedBalance}>
            <span>{`$${formattedBalance}`}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "ledgers",
      headerName: "Ledgers",
      minWidth: 50,
      renderCell: (params) => {
        const { doc_id } = params?.row;
        return (
          <Tooltip title="View" key="view">
            <IconButton
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "4px",
                background:
                  "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                "&:hover": {
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                },
              }}
              onClick={() => handleOnChangeLedgers(doc_id)}
            >
              <RemoveRedEyeIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
        );
      },
    },

    {
      field: "registered_date",
      headerName: "Registered Date",
      minWidth: 250,
      hideOnMobile: true,
      renderCell: (params) => {
        const { registered_date } = params?.row;
        const seconds = registered_date?.seconds;
        const nanoseconds = registered_date?.nanoseconds;
        const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
        const date = moment(milliseconds);
        return <div>{date.format("MMMM Do YYYY, h:mm:ss A")}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { status } = params?.row;
        const renderStatusChip = () => {
          switch (status) {
            case 0:
              return (
                <Chip
                  label="Pending"
                  sx={{ color: "#fff", backgroundColor: "#FFC107" }}
                />
              );
            case 1:
              return (
                <Chip
                  label="Approved"
                  sx={{ color: "#fff", backgroundColor: "#5fdba7" }}
                />
              );
            case 2:
              return (
                <Chip
                  label="Rejected"
                  sx={{ color: "#fff", backgroundColor: "darkred" }}
                />
              );
            case 3:
              return (
                <Chip
                  label="Blocked"
                  sx={{ color: "#fff", backgroundColor: "darkred" }}
                />
              );
            default:
              return null;
          }
        };

        return renderStatusChip();
      },
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 170,
      filterable: false,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { status, doc_id } = params?.row;
        const actionButtons = [];
        if (status === 1) {
          actionButtons.push(
            <Tooltip title="Edit" key="edit">
              <IconButton
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  background: "green",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  },
                }}
                onClick={() =>
                  navigate("/admin/editcustomer", {
                    state: { customData: params?.row },
                  })
                }
              >
                <EditIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>,
            <Tooltip title="View" key="view">
              <IconButton
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  },
                }}
                onClick={() =>
                  navigate("/admin/viewcustomer", {
                    state: { customData: params?.row, page },
                  })
                }
              >
                <RemoveRedEyeIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>,
            <Tooltip title="Block" key="block">
              <IconButton
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  background: "red",
                  "&:hover": { background: "#C62828" },
                }}
                onClick={() =>
                  handleUpdateApproveRejectBlockUnBlockStatus(doc_id, 3)
                }
              >
                <PersonIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          );
        } else if (status === 2 || status === 3) {
          actionButtons.push(
            <>
              <Tooltip title="UnBlock" key="UnBlock">
                <IconButton
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    background: "red",
                    "&:hover": { background: "#c62828" },
                  }}
                  onClick={() =>
                    handleUpdateApproveRejectBlockUnBlockStatus(doc_id, 1)
                  }
                >
                  <PersonOffIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="View" key="view">
                <IconButton
                  sx={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    "&:hover": {
                      background:
                        "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    },
                  }}
                  onClick={() =>
                    navigate("/admin/viewcustomer", {
                      state: {
                        customData: params?.row,
                        page,
                      },
                    })
                  }
                >
                  <RemoveRedEyeIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            </>
          );
        } else if (status === 0) {
          actionButtons.push(
            <>
              <Tooltip title="Approve Request">
                <IconButton
                  sx={{
                    background: "#4caf50",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    "&:hover": { background: "#388e3c" },
                  }}
                  onClick={() =>
                    handleUpdateApproveRejectBlockUnBlockStatus(doc_id, 1)
                  }
                >
                  <CheckIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Reject Request">
                <IconButton
                  sx={{
                    background: "red",
                    "&:hover": { background: "#c62828" },
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                  }}
                  onClick={() =>
                    handleUpdateApproveRejectBlockUnBlockStatus(doc_id, 2)
                  }
                >
                  <CloseIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            </>
          );
        }
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {actionButtons}
          </Box>
        );
      },
    },
  ];
  // ============= END TABLE COLUMN ===============
  // ============= START HANDLE_ALL_STATUS_LIST ===============
  const isStatusList = [
    { name: "All", id: null },
    { id: 0, name: "Pending", label: "Pending" },
    { id: 1, name: "Approve", label: "Approve" },
    { id: 2, name: "Blocked", label: "Blocked" },
  ];
  const isCategoryActiveList = [
    { name: "All", id: null },
    { id: 0, name: "Individual Customer", label: "Individual Customer" },
    { id: 1, name: "Corporate Customer", label: "Corporate Customer" },
  ];
  useEffect(() => {
    const unsubscribe = getCustomerList(
      page,
      pageSize,
      debouncedSearch,
      isStatus,
      isCategoryActive
    );
    return () => unsubscribe();
  }, [debouncedSearch, page, isStatus, isCategoryActive]);
  const getCustomerList = (
    currentPage = 0,
    pageSize = 50,
    debouncedSearch = "",
    statusFilter = null,
    categoryFilter = null
  ) => {
    setIsLoading(true);
    const q = query(
      collection(DATABASE, "users"),
      orderBy("registered_date", "desc")
    );
    return onSnapshot(
      q,
      (querySnapshot) => {
        const allData = querySnapshot?.docs
          .filter((doc) => doc?.data()?.role === 1)
          .map((doc, index) => ({
            rowid: index + 1,
            id: doc.id,
            ...doc.data(),
          }));

        const trimmedSearch = debouncedSearch?.trim().toLowerCase();
        const filteredData = allData?.filter((customer) => {
          const email = customer?.email?.trim()?.toLowerCase() || "";
          const name = customer?.full_name?.trim()?.toLowerCase() || "";
          const net_balance =
            customer?.net_balance != null
              ? customer?.net_balance.toString().trim()
              : "";
          return (
            email.includes(trimmedSearch) ||
            name.includes(trimmedSearch) ||
            net_balance?.includes(trimmedSearch)
          );
        });

        const statusFilteredData =
          statusFilter === null
            ? filteredData
            : filteredData.filter(
              (customer) => customer?.status === statusFilter
            );
        const finalData =
          categoryFilter === null
            ? statusFilteredData
            : statusFilteredData.filter(
              (customer) => customer?.user_type === categoryFilter
            );

        setTotalCount(finalData.length);
        setCustomerList(
          finalData.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
        );
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching customers:", error);
        setIsLoading(false);
      }
    );
  };
  const handleUpdateStatusCategory = (id) => {
    setIsCategoryActive(id === null ? null : id);
    setPage(0);
  };
  const handleUpdateActiveUnVerifyBlockStatus = (id) => {
    setIsStatus(id === null ? null : id);
    setPage(0);
  };
  const handleUpdateApproveRejectBlockUnBlockStatus = async (
    docId,
    newStatus
  ) => {
    setIsLoading(true);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You want to ${newStatus === 1 ? "approve" : newStatus === 2 ? "Block" : "Reject"
        } this customer`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await updateDoc(doc(DATABASE, "users", docId), { status: newStatus });
        toast.success(
          newStatus === 1 ? "Approved!" : "Blocked!",
          `Customer has been ${newStatus === 1 ? "accepted" : "Blocked"}.`,
          "success"
        );
        setPage(0);
        getCustomerList(
          page,
          pageSize,
          debouncedSearch,
          isStatus,
          isCategoryActive
        );
      } catch (error) {
        console.error("Error updating customer status:", error);
        toast.success("Error!", "Something went wrong. Try again!", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    getCustomerList(
      0,
      newPageSize,
      debouncedSearch,
      isStatus,
      isCategoryActive
    );
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
    getCustomerList(
      newPage,
      pageSize,
      debouncedSearch,
      isStatus,
      isCategoryActive
    );
  };
  const handleOnChangeLedgers = (userID) => {
    setSelectCustomerLedgersID(userID);
    setViewLedgersModel(true);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          paddingTop: { xs: '60px', sm: "0px" }
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Customer Management
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => navigate("/admin/addcustomer")}
                sx={{
                  background:
                    "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                  color: "#fff",
                  height: "46px",
                  padding: "0px 20px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background:
                      "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                    color: "#fff",
                  },
                }}
              >
                + Add Customer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSearch
              placeholder="Search Customer (Email, Full Name, Ledger)"
              value={searchCustmer}
              onChange={(event) => setSearchCustmer(event.target.value)}
              onClear={() => setSearchCustmer("")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomDropDown
                  sx={{ height: "46px" }}
                  placeholder={"Select Category"}
                  menuList={isCategoryActiveList}
                  value={isCategoryActive}
                  onChange={(e) => handleUpdateStatusCategory(e.target.value)}
                  getLabel={(item) => item?.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomDropDown
                  sx={{ height: "46px" }}
                  placeholder={"Select Status"}
                  menuList={isStatusList}
                  value={isStatus}
                  onChange={(e) =>
                    handleUpdateActiveUnVerifyBlockStatus(e.target.value)
                  }
                  getLabel={(item) => item?.name}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            paddingTop: "24px",
          }}
        >
          <TableContainer
            rows={customerList}
            columns={columns}
            loading={isLoading}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            paginationShow={true}
          />
        </Box>
      </Box>
      <ViewLedgersModel
        open={viewLedgersModel}
        onClose={() => setViewLedgersModel(false)}
        selectCustomerLedgersID={selectCustomerLedgersID}
      />
    </>
  );
};

export default CustomerManagement;
