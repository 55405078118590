import { Box } from '@mui/material'
import React from 'react'
import { Vortex } from 'react-loader-spinner'

const CommonLoader = () => {
    return (
        <Box sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '5px',
            padding: '10px',
            color: 'white',
            fontSize: '20px'
        }}>
            <Vortex
                visible={true}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                // wrapperClass="vortex-wrapper"
                colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />
        </Box>
    )
}

export default CommonLoader