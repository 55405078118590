import { Box, Grid, Typography, Menu, MenuItem, IconButton, Divider } from "@mui/material";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import WalletIcon from "@mui/icons-material/Wallet";
import AddCardIcon from "@mui/icons-material/AddCard";
import LineChart from "../../../Components/DashboardChart/LineChart";
import { collection, getDocs, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { AUTH_LOGIN, DATABASE } from "../../../Config/firebase";
import Skeleton from "react-loading-skeleton";
import { dolorFormatAmount } from "../../../Utils/Services";
import dayjs from "dayjs";
import CustomDateRangePicker from "../../../Components/CustomDateRangePicker/CustomDateRangePicker";
import Swal from "sweetalert2";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const navigate = useNavigate();
  const [totalCustomer, setTotalCustomer] = React.useState(0);
  const [corporateCustomer, setCorporateCustomer] = React.useState(0);
  const [individualCustomer, setIndividualCustomer] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading1, setIsLoading1] = React.useState(false);
  const [allTransactionsList, setAllTransactionsList] = React.useState([]);
  const [totalYearlyPerformances, setTotalYearlyPerformances] = React.useState(0);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user_info = JSON.parse(localStorage.getItem("fiddux_info")) || null;
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null)
  const handleLogoutClick = () => {
    setAnchorEl(null);
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await signOut(AUTH_LOGIN);
        localStorage.clear();
        Swal.fire({
          title: "Logged Out",
          text: "You have been successfully logged out. Redirecting to login page...",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          navigate("/login");
        });
      }
    });
  };
  const [commonTransaction, setCommonTransaction] = React.useState([
    {
      withdrawAmount: 0,
      depositAmount: 0,
      totalRevenue: 0,
      depositIndividuals: 0,
      depositCorporate: 0,
      withdrawalsIndividuals: 0,
      withdrawalsCorporate: 0,
      revenueIndividuals: 0,
      revenueCorporate: 0,
      performanceIndividual: 0,
      performanceCorporate: 0,
      performanceAll: 0
    }
  ]);
  React.useEffect(() => {
    const getTotalCustomer = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(DATABASE, "users"));
        const allData = querySnapshot.docs
          .filter(doc => doc?.data()?.role === 1 && doc?.data()?.status === 1)
          .map((doc, index) => ({
            id: doc.id,
            ...doc.data(),
          }));
        setTotalCustomer(allData?.length || 0);
        setCorporateCustomer(allData?.filter((customer) => customer?.user_type === 1)?.length || 0);
        setIndividualCustomer(allData?.filter(customer => customer?.user_type === 0)?.length || 0);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setIsLoading(false);
      }
    };
    const getYearlyList = async () => {
      setIsLoading(true);
      let q = query(collection(DATABASE, "yearly_graph"), orderBy('created_at', "desc"), limit(12));

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const rowData = querySnapshot?.docs?.map((doc, index) => ({
          ...doc.data(),
        }));
        const total = rowData?.reduce((total, item) => total + item?.amount, 0);
        setTotalYearlyPerformances(total);
        setIsLoading(false);
      }, (error) => {
        setIsLoading(false);
        console.error(error);
      });
      return unsubscribe;
    };
    getYearlyList()
    getTotalCustomer();
  }, [])
  React.useEffect(() => {
    const getTransactionHistory = async () => {
      setIsLoading1(true);
      try {
        const querySnapshot = await getDocs(collection(DATABASE, "transection_requests"));
        const allData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        let filteredTransactions = allData;
        if (startDate && endDate) {
          filteredTransactions = filteredTransactions?.filter(doc => {
            const transactionDate = dayjs(doc?.date?.toDate());
            return transactionDate.isBetween(startDate, endDate, null, '[]');
          });
        }
        const depositAmount = filteredTransactions?.filter(doc => doc?.request_type === 0 && doc?.request_status === 2)
          ?.reduce((acc, curr) => acc + curr?.total_amount, 0);

        const withdrawAmount = filteredTransactions?.filter(doc => doc?.request_type === 1 && doc?.request_status === 5)
          ?.reduce((acc, curr) => acc + curr?.withdraw_amount, 0);
        const totalRevenueCalculated = depositAmount - withdrawAmount;

        const usersQuerySnapshot = await getDocs(collection(DATABASE, "users"));
        const allUsers = usersQuerySnapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const individualUsers = allUsers?.filter(user => user?.user_type === 0);
        const individualUserIds = individualUsers?.map(user => user?.id);
        const corporateUsers = allUsers?.filter(user => user?.user_type === 1);
        const corporateUserIds = corporateUsers?.map(user => user?.id);

        const depositIndividuals = filteredTransactions
          ?.filter(doc => individualUserIds.includes(doc?.user_id) && doc?.request_type === 0 && doc?.request_status === 2)
          ?.reduce((acc, curr) => acc + curr?.total_amount, 0);
        const depositCorporate = filteredTransactions
          ?.filter(doc => corporateUserIds.includes(doc?.user_id) && doc?.request_type === 0 && doc?.request_status === 2)
          ?.reduce((acc, curr) => acc + curr?.total_amount, 0);
        const withdrawalsIndividuals = filteredTransactions
          ?.filter(doc => individualUserIds.includes(doc?.user_id) && doc?.request_type === 1 && doc?.request_status === 5)
          ?.reduce((acc, curr) => acc + curr?.total_amount, 0);
        const withdrawalsCorporates = filteredTransactions
          ?.filter(doc => corporateUserIds.includes(doc?.user_id) && doc?.request_type === 1 && doc?.request_status === 5)
          ?.reduce((acc, curr) => acc + curr?.total_amount, 0);

        const totalRevenueCalculatedIndividuals = depositIndividuals - withdrawalsIndividuals;
        const totalRevenueCalculatedCorporate = depositCorporate - withdrawalsCorporates;
        const totalPerformanceIndividual = filteredTransactions?.filter(item => individualUserIds.includes(item?.user_id))
        .reduce((acc, item) => {
          return acc + (item?.profit_loss || 0);
        }, 0);
        const totalPerformanceCorporate = filteredTransactions?.filter(item => corporateUserIds.includes(item?.user_id))
        .reduce((acc, item) => {
          return acc + (item?.profit_loss || 0);
        }, 0);
        const totalPerformance = filteredTransactions?.reduce((acc, item) => {
          return acc + (item?.profit_loss || 0);
        }, 0);
        setCommonTransaction([{
          withdrawAmount: withdrawAmount,
          depositAmount: depositAmount,
          totalRevenue: totalRevenueCalculated,
          depositIndividuals: depositIndividuals,
          depositCorporate: depositCorporate,
          withdrawalsIndividuals: withdrawalsIndividuals,
          withdrawalsCorporate: withdrawalsCorporates,
          revenueIndividuals: totalRevenueCalculatedIndividuals,
          revenueCorporate: totalRevenueCalculatedCorporate,
          performanceIndividual: totalPerformanceIndividual,
          performanceCorporate: totalPerformanceCorporate,
          performanceAll: totalPerformance
        }]);
        setAllTransactionsList(filteredTransactions || []);
        setIsLoading1(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setIsLoading1(false);
      }
    };

    getTransactionHistory();
  }, [startDate, endDate]);
  return (
    <React.Fragment>
      <Box sx={{
        width: '100%',
      }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop:{xs:'60px', sm:"0px"}
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Dashboard
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              position: "relative",
              
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "500", display:{xs:'none', sm:"flex"} }}>
              Welcome Admin
            </Typography>
            <IconButton
              size="small"
              sx={{ ml: 2 }}
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <Avatar sx={{ width: 45, height: 45 }}>A</Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <Box sx={{ padding: 2, width: 200 }}>
                <Typography sx={{ fontSize: "16px", color: "#555" }}>
                  {user_info?.email}
                </Typography>
                <Divider sx={{ my: 1 }} />
              </Box>
              <MenuItem onClick={handleLogoutClick} >Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box sx={{
          width: '100%',
          paddingTop: '10px'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {isLoading ? <Skeleton height={80} /> : <Box sx={{
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#28282B',
                padding: '10px',
                borderRadius: '10px',
              }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                    alignItems: "center",
                    color: "#fff",

                  }}
                >
                  <GroupsIcon style={{ fontSize: "28px", color: "#fff" }} />
                  <Typography sx={{ fontSize: "20px" }}>Users</Typography>
                </Box>
                <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                  {totalCustomer}
                </Typography>
              </Box>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {isLoading ? <Skeleton height={80} /> : <Box sx={{
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#28282B',
                padding: '10px',
                borderRadius: '10px',
              }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon style={{ fontSize: "28px", color: "#fff" }} />
                  <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                    Corporate Users
                  </Typography>
                </Box>
                <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                  {corporateCustomer}
                </Typography>
              </Box>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {isLoading ? <Skeleton height={80} /> : <Box sx={{
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#28282B',
                padding: '10px',
                borderRadius: '10px',
              }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon style={{ fontSize: "28px", color: "#fff" }} />
                  <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                    Individual Users
                  </Typography>
                </Box>
                <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                  {individualCustomer}
                </Typography>
              </Box>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              {isLoading ? <Skeleton height={80} /> : <Box sx={{
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#28282B',
                padding: '10px',
                borderRadius: '10px',
              }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                  <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                    Last 12 Month Performance
                  </Typography>
                </Box>
                <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                  {totalYearlyPerformances?.toFixed(2)} %
                </Typography>
              </Box>}
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{
                width: '100%',
                background: "	#28282B",
                padding: '10px',
                borderRadius: '10px',
              }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{
                      width: {xs:'100%',md:"400px"},
                    }}>
                      <CustomDateRangePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      background: 'rgb(9, 20, 15)',
                      padding: '10px',
                      borderRadius: '10px',
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <AddCardIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Deposits
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.depositAmount)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      background: 'rgb(9, 20, 15)',
                      padding: '10px',
                      borderRadius: '10px',
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Withdrawals
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.withdrawAmount)}
                      </Typography>
                    </Box>}
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                      background: 'rgb(9, 20, 15)',
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Total Revenue
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.totalRevenue)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                      background: 'rgba(12, 44, 80, 1)',
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Deposit Individuals
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.depositIndividuals)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                      background: 'rgba(12, 44, 80, 1)',
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Withdrawals Individuals
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                          ${dolorFormatAmount(commonTransaction[0]?.withdrawalsIndividuals)}
                        </Typography>
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                      background: 'rgba(12, 44, 80, 1)',
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Revenue Individuals
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.revenueIndividuals)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                       background:'rgba(29, 111, 74, 1)'
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Deposit Corporate
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.depositCorporate)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                       background:'rgba(29, 111, 74, 1)'
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Withdrawals Corporate
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.withdrawalsCorporate)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                      background:'rgba(29, 111, 74, 1)'
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Revenue Corporate
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.revenueCorporate)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                       background:'rgb(76, 229, 160)'
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Performance Individual
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.performanceIndividual)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                       background:'rgb(76, 229, 160)'
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Performance Corporate
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.performanceCorporate)}
                      </Typography>
                    </Box>}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    {isLoading1 ? <Skeleton height={80} /> : <Box sx={{
                      padding: '10px',
                      borderRadius: '10px',
                       background:'rgb(76, 229, 160)'
                    }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <WalletIcon style={{ fontSize: "28px", color: "#fff" }} />
                        <Typography sx={{ fontSize: "20px", color: "#fff" }}>
                          Performance All
                        </Typography>
                      </Box>
                      <Typography sx={{ pt: 1, fontSize: "24px", fontWeight: "500", color: "#fff" }}>
                        ${dolorFormatAmount(commonTransaction[0]?.performanceAll)}
                      </Typography>
                    </Box>}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{
                backgroundColor: '#28282B',
                padding: '10px',
                borderRadius: '10px',
              }}>
                <Typography sx={{ fontSize: "22px", fontWeight: "500", color: "#fff", margin: '0px 0px 10px 0px' }}>Last 5 Year Deposit and Withdrawal</Typography>
                {isLoading ? <Skeleton height={200} /> : <LineChart
                  allTransactionsList={allTransactionsList}
                />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
