import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const CustomDateRangePicker = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate
}) => {
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  useEffect(() => {
    if (start && end && end.isBefore(start)) {
      setEnd(start.add(1, 'month').endOf('month'));
      setEndDate(start.add(1, 'month').endOf('month'));
    }
  }, [start, end, setEndDate]);

  const handleStartDateChange = (newValue) => {
    setStart(newValue);
    setStartDate(newValue);
    const defaultEndDate = newValue ? newValue.add(1, 'month').endOf('month') : null;
    setEnd(defaultEndDate);
    setEndDate(defaultEndDate);
  };

  const handleEndDateChange = (newValue) => {
    setEnd(newValue);
    setEndDate(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box>
          <Typography sx={{ color: '#fff', fontSize: { xs: '16px', sm: '18px' }, mb: 1 }}>
            Start Date
          </Typography>
          <DatePicker
            value={start}
            onChange={handleStartDateChange}
            maxDate={end}
            slotProps={{
              textField: {
                variant: 'outlined',
                fullWidth: true,
                sx: {
                  backgroundColor: '#fff',
                  '& .MuiInputBase-root': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                },
                placeholder: 'Select Start Date',
              },
            }}
          />
        </Box>
        <Box>
          <Typography sx={{ color: '#fff', fontSize: { xs: '16px', sm: '18px' }, mb: 1 }}>
            End Date
          </Typography>
          <DatePicker
            value={end}
            onChange={handleEndDateChange}
            minDate={start}
            slotProps={{
              textField: {
                variant: 'outlined',
                fullWidth: true,
                sx: {
                  backgroundColor: '#fff',
                  '& .MuiInputBase-root': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                },
                placeholder: 'Select End Date',
              },
            }}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
