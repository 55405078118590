import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import { doc, setDoc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import "react-quill/dist/quill.snow.css";
import { DATABASE } from "../../../Config/firebase";
import { toast } from "react-toastify";

const PrivacyPolicy = () => {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);
  const fetchPrivacyPolicy = async () => {
    try {
      setIsLoading(true);
      const docRef = doc(DATABASE, "app_setting", "privacy");
      const docSnap = await getDoc(docRef);
      if (docSnap?.exists()) {
        setData(docSnap?.data()?.html_markup);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching Privacy Policy : ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      privacy_policy: data || "",
    },
    validationSchema: Yup.object({
      privacy_policy: Yup.string().required("Privacy Policy is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsUpdating(true);
      try {
        const docRef = doc(DATABASE, "app_setting", "privacy");

        if (data) {
          await updateDoc(docRef, {
            html_markup: values?.privacy_policy,
            doc_id:"policy",
            type:0,
            update_date:Timestamp.now(),
          });
          toast.success("Privacy Policy Updated Successfully");
        } else {
          await setDoc(docRef, {
            html_markup: values?.privacy_policy,
            doc_id:"policy",
            type:0,
            update_date:Timestamp.now(),
          });
          toast.success("Privacy Policy Saved Successfully");
        }
        resetForm();
      } catch (error) {
        console.error("Error saving Privacy Policy : ", error);
      } finally {
        setIsUpdating(false);
        fetchPrivacyPolicy();
      }
    },
  });

  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "#fff",paddingTop:{xs:'60px', sm:"0px"} }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Privacy Policy
          </Typography>
        </Box>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          style={{
            position: "relative",
            backgroundColor: "#fff",
            padding: "15px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
        >
          {isLoading ? (
            <Skeleton width={"100%"} height={"230px"} />
          ) : (
            <ReactQuill
              placeholder="Description"
              value={formik?.values?.privacy_policy}
              ref={ref}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [
                    { align: "right" },
                    { align: "center" },
                    { align: null },
                    { align: "justify" },
                  ],
                ],
              }}
              onChange={(content) => {
                content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                formik.setFieldValue("privacy_policy", content);
              }}
              className="termsCondtionContent"
            />
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={
                !formik?.values?.privacy_policy
                  ?.replace(/<[^>]+>/g, "")
                  .trim() || isUpdating
              }
              sx={{
                width: "120px",
                height: "48px",
                fontSize: "18px",
                background: "linear-gradient(148.97deg, #0C2C50 0%, #450F5B 100%)",
                "&:hover": { bgcolor: "#6EE498" },
                borderRadius: "12px",
              }}
            >
              {isUpdating ? "Saving..." : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
