import {
  Box,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomTextField from "../../../../Components/CustomTextField/CustomTextField";
import CustomSubmitButton from "../../../../Components/CustomSubmitButton/CustomSubmitButton";
import { DATABASE } from "../../../../Config/firebase";
import {
  doc,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { dolorFormatAmount } from "../../../../Utils/Services";
const FullWithdrawModel = (props) => {
  const { open, onClose, offerAmountDetails, handleOfferAmountOnSubmit } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [value, setValue] = useState(0);
  const [type, setType] = useState(0);
  const is_Offer_Price = offerAmountDetails?.total_amount;
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setType(name === "Profit" ? 1 : 2);
    } else {
      setType(0);
    }
  };
  const handlePositiveNumberChange = (e) => {
    const { name, value } = e.target;

    const validValue = value === "" || /^\d+(\.\d{0,2})?$/.test(value);

    if (validValue) {
      formik.setFieldValue(name, value);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      profit_loss_percent: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .required("Amount is required")
        .positive("Amount must be positive"),
      // .max(
      //   is_Offer_Price,
      //   `Amount cannot be greater than ${"full withdraw"}`
      // ),
      profit_loss_percent: Yup.number().required(
        "Profit Loss Percent is required"
      ),
      // .positive("Amount must be positive")
      // .max(
      //   is_Offer_Price,
      //   `Amount cannot be greater than ${"full withdraw"}`
      // ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) return;
      else {
        try {
          if (offerAmountDetails?.request_status === 1) {
            const userDoc = await getDoc(
              doc(DATABASE, "users", offerAmountDetails.user_id)
            );
            if (!userDoc.exists()) {
              throw new Error("User does not exist");
            }
          }
          const updateData = {
            offered_amount: Math.abs(values?.amount),
            request_status: offerAmountDetails?.request_status,
            request_approval_time: serverTimestamp(),
            profit_loss_percent:
              type === 2
                ? -Math.abs(values.profit_loss_percent)
                : Math.abs(values.profit_loss_percent),
          };
          await updateDoc(
            doc(DATABASE, "transection_requests", offerAmountDetails?.doc_id),
            updateData
          );
          handleOfferAmountOnSubmit();
        } catch (error) {
          console.error("Error updating withdraw status:", error);
          toast.error(error.message || "Something went wrong. Try again!", {
            autoClose: 3000,
          });
        } finally {
          setIsLoading(false);
          resetForm();
        }
      }
    },
  });
  useEffect(() => {
    const percent = parseFloat(formik.values.profit_loss_percent);
    if (type === 1 && percent > 0) {
      const calculatedAmount =
        is_Offer_Price + (is_Offer_Price * percent) / 100;
      formik.setFieldValue("amount", calculatedAmount);
    } else if (type === 2 && percent > 0) {
      const calculatedAmount =
        is_Offer_Price - (is_Offer_Price * percent) / 100;
      formik.setFieldValue("amount", calculatedAmount);
    } else {
      formik.setFieldValue("amount", "");
    }
  }, [formik.values.profit_loss_percent, type, is_Offer_Price]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "20px 30px",
          height: value === 0 ? "auto" : "300px",
          overflow: "scroll",
          "::-webkit-scrollbar": { width: "0px", height: "0px" },
        },
        "& .MuiDialogContent-root": {
          overflowY: "initial",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      {/* <Tabs value={value} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Offer Amount" />
        <Tab label="Full Withdraw" />
      </Tabs> */}
      {/* {value === 0 && ( */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
        style={{
          paddingTop: "20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#101010",
                fontFamily: "Outfit",
              }}
            >
              Net Balance : $
              {dolorFormatAmount(offerAmountDetails?.total_amount)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#101010",
                fontFamily: "Outfit",
              }}
            >
              Requested Amount : $
              {dolorFormatAmount(offerAmountDetails?.total_amount)}
            </Typography>
          </Grid>
          {/* <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              ></Box>
            </Grid> */}
        </Grid>
        <Box
          sx={{
            padding: "20px 0px 0px 0px",
          }}
        >
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <FormGroup>
                <Box sx={{ display: "flex" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Profit"
                        checked={type === 1}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Profit"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="Loss"
                        checked={type === 2}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Loss"
                  />
                </Box>
                <CustomTextField
                  type="number"
                  min="0"
                  max="1"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder={
                    type === 1
                      ? "Enter Profit Percentage %"
                      : type === 2
                      ? "Enter Loss Percentage %"
                      : "Enter Percentage %"
                  }
                  name="profit_loss_percent"
                  value={formik.values.profit_loss_percent}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={
                    formik.touched.profit_loss_percent &&
                    formik.errors.profit_loss_percent
                  }
                  onChange={(e) => {
                    handlePositiveNumberChange(e);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "10px",
                      border: "1px solid #CACACA",
                      color: "black",
                      fontSize: "18px",
                      background: "white",
                      fontFamily: "Outfit",
                      // display: type === 1 || type === 2 ? "block" : "none",
                    },
                  }}
                  style={{ color: "black", fontSize: "18px" }}
                  isLoading={isLoading}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                disabled={true}
                label={
                  type === 1
                    ? "Amount after Profit"
                    : type == 2
                    ? "Amount after Loss"
                    : "Amount"
                }
                type="text"
                placeholder="Enter Amount"
                name="amount"
                value={`$${dolorFormatAmount(formik.values.amount)}`}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                errors={formik.touched.amount && formik.errors.amount}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: "1px solid #CACACA",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSubmitButton
                disabled={formik.values.profit_loss_percent == 0}
                btnTitle={"Offer Request"}
                loading={isLoading}
                type="submit"
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  borderBottom: "1px solid #CACACA",
                  height: 0,
                  marginRight: 1,
                }}
              />
              <Box sx={{ px: 2, fontWeight: "500", color: "#101010" }}>OR</Box>
              <Box
                sx={{
                  flex: 1,
                  borderBottom: "1px solid #CACACA",
                  height: 0,
                  marginLeft: 1,
                }}
              />
            </Box>

            <Grid item xs={12}>
              <CustomSubmitButton
                disabled={formik.values.profit_loss_percent > 0}
                btnTitle={"Accept Full Withdraw"}
                loading={isLoading1}
                type="button"
                onSubmit={async () => {
                  setIsLoading1(true);
                  try {
                    const updateData = {
                      request_status: 2,
                      request_approval_time: Timestamp.now(),
                      full_withdraw_paid: 0,
                    };
                    await updateDoc(
                      doc(
                        DATABASE,
                        "transection_requests",
                        offerAmountDetails?.doc_id
                      ),
                      updateData
                    );
                    toast.success("Full withdraw accepted");
                    handleOfferAmountOnSubmit();
                  } catch (error) {
                    console.error("Error accepting withdraw:", error);
                    toast.error("Failed to accept full withdraw");
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />{" "}
            </Grid>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

export default FullWithdrawModel;
