import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import WalletIcon from '@mui/icons-material/Wallet';
import AddCardIcon from '@mui/icons-material/AddCard';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InfoIcon from '@mui/icons-material/Info';
export const NavMenuList = [
  {
    title: "Dashboard",
    path: '/admin/dashboard',
    icon: <SpaceDashboardIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <SpaceDashboardIcon style={{fontSize:"18px",color:"black"}}/>,
  },
  {
    title: "Customer Management",
    path: '/admin/customermanagement',
    icon: <PersonAddIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <PersonAddIcon style={{fontSize:"18px",color:"black"}}/>,
  },
  {
    title: "Monthly Return",
    path: '/admin/monthlygraph',
    icon: <EqualizerIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <EqualizerIcon style={{fontSize:"18px",color:"black"}}/>,
  },
  {
    title: "Yearly Management",
    path: '/admin/yearly-graph',
    icon: <EqualizerIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <EqualizerIcon style={{fontSize:"18px",color:"black"}}/>,
  },
  {
    title: "Funds Deposit",
    path: '/admin/fundsdeposit',
    icon: <AddCardIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <AddCardIcon style={{fontSize:"18px",color:"black"}}/>,
   
  },
  {
    title: "Withdraw funds",
    path: '/admin/withdrawfunds',
    icon: <WalletIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <WalletIcon style={{fontSize:"18px",color:"black"}}/>,
  },

  // {
  //   title: "Yearly Graph",
  //   path: '/admin/yearlygraph',
  //   icon: <EqualizerIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
  //   active: <EqualizerIcon style={{fontSize:"18px",color:"black"}}/>,
  // },
  
];

export const SettingMenuList = [
  {
    title: "Terms And Conditions",
    path: '/admin/termsandconditions',
    icon: <InsertDriveFileIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <InsertDriveFileIcon style={{fontSize:"18px",color:"black"}}/>,
  },
  {
    title: "Privacy Policy",
    path: '/admin/privacypolicy',
    icon: <InsertDriveFileIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <InsertDriveFileIcon style={{fontSize:"18px",color:"black"}}/>,
  },
  {
    title: "About Us",
    path: '/admin/aboutus',
    icon: <InfoIcon style={{fontSize:"18px",color:'#ADADAD'}}/>,
    active: <InfoIcon style={{fontSize:"18px",color:"black"}}/>,
  },
]
